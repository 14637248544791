import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-chat"
export default class extends Controller {

  static targets = ['messagesContainer', 'messagesScroller', 'chatWindow', 'noMessages']

  static values = {
    limit: {
      type: Number,
      default: 10,
    },
    offset: {
      type: Number,
      default: 0
    },
    keyword: {
      type: String,
      default: ""
    },
    shownIds: {
      type: Array,
      default: []
    }
  }

  connect() {
    this.shownIdsValue = []
    this.getMessages();

    document.addEventListener('turbo:before-stream-render', (e) => {
      if(e.detail.newStream.target == 'dashboard_messages'){
        this.shownIdsValue = []
        this.getMessages();
      }
    })

    Turbo.cache.exemptPageFromPreview()

    window.addEventListener("turbo:before-visit", (event) => {
      this.shownIdsValue.length = 0
      this.offsetValue = 0; 
    })
    
    this.messagesScrollerTarget.addEventListener('scroll', (e) => {
      if( e.target.scrollTop === (e.target.scrollHeight - e.target.offsetHeight)){
        this.offsetValue = this.offsetValue + this.limitValue
        this.getMessages();
      }
    })

  }

  getMessages(){

    const csrf = document.querySelector('meta[name="csrf-token"]')
    fetch('/dashboard/messages', {
        method : "post",
        headers: {
          'X-CSRF-Token': csrf.content,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          limit: this.limitValue,
          offset: this.offsetValue,
          keyword: this.keywordValue,
          shown_ids: this.shownIdsValue
        })
    })
    .then(r => r.json())
    .then(data => {

      if(Object.keys(data).length == 0 && this.messagesContainerTarget.innerHTML.trim() == ""){
          this.noMessagesTarget.classList.remove('d-none')
          this.chatWindowTarget.classList.add('d-none')
      }

      const translation = JSON.parse(this.element.getAttribute('data-lang'))

      if(this.offsetValue == 0){
        this.messagesContainerTarget.innerHTML = ""
      }

      let html = this.messagesContainerTarget.innerHTML

      for (const [key, value] of Object.entries(data)) {
        const ID = 'message-date-group'+key

        if(!document.getElementById(ID)){
          html += "<div class='dashboard-messages-separator my-4' id='"+ID+"'><div>"+key+"</div></div>";
        }

        for(let i = 0; i < value.length; i++){
          const item = value[i]

          if(!this.shownIdsValue.includes(item.patient_id)) this.shownIdsValue.push(item.patient_id);

          const isUnread = item.unreadcount > 0

          html += "<a href='/patients/"+item.patient_id+"/messages' class='p-3 d-block text-decoration-none position-relative mb-2 "+(isUnread ? 'unread-msg-dashboard' : '')+"'>";
            html += "<div class='d-flex align-items-start mb-2'>";
              html += "<div class='dashboard-user-photo'>";
                html += "<img src='"+item.photourl+"' onerror='this.remove()'>";
                html += item.firstname[0]+item.lastname[0]
              html += "</div>";

              html += "<div class='ms-3'>";
                html += "<div class='d-flex align-items-end'>";
                  html += "<div class='fs-14px lh-14px fw-bold text-dark-blue'>"+item.firstname+' '+item.lastname+"</div>"
                  html += "<div class='dashboard-message-recovery-status ms-1 "+item.recovery_status+"'></div>"
                html += "</div>";

                html += "<div class='text-gray6 fs-12px'>"+translation.recovery_time+": "+(item.recovery_time ?? '-')+"</div>"

              html += "</div>";

              const time = item.messagetime.split('T')[1].split('.000Z')[0].split(':')

              html += "<div class='text-gray6 fs-12px ms-auto'>"+time[0]+':'+time[1]+"</div>"

            html += "</div>";

            html += "<div class='text-dark-blue fs-12px'>"+item.message+"</div>"

            if(isUnread){
              html += "<div class='to-be-read px-3 py-1'>"+translation.to_be_read+"</div>"
            }
          html += "</a>";
        }
      }

      this.messagesContainerTarget.innerHTML = html

    })
  }

  search(e){
    this.offsetValue = 0;
    this.keywordValue = e.target.value;
    this.shownIdsValue.length = 0;

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getMessages()
    }, 500)
  }
}
