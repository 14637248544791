import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"

// Connects to data-controller="create-patient"
export default class extends Controller {

  static targets = ["grayLayer", "carePlanSelector", "specialtySelector", "nhsInput", "firstName", "lastName", "dateOfBirth", "gender", "nhsError"]

  static values = {
    carePlanOptions: [],
    nhsErrorMsg: '',
    nhsMismatchErrorMsg: ''
  }

  async connect() {
    initIntlTelInput()
    this.setRequireProcedureState()
    this.carePlanOptionsValue.length = 0
    await this.carePlanSelectorTarget.querySelectorAll('option').forEach(item => {
      this.carePlanOptionsValue.push(item)
    })
    this.setOperationTypes(this.specialtySelectorTarget, false)

    const nhsSearchBtn = document.getElementById('nhsSearchBtn')

    //if nhs search enabled set event listener
    if(nhsSearchBtn){
      this.nhsEventListener(nhsSearchBtn);
    }
  }

  procedureToggled(e){
    if(e.target.checked){
      document.querySelector("input[name=require_procedure").value = 1
      this.grayLayerTarget.style.display = 'none';
    }else {
      document.querySelector("input[name=require_procedure").value = 0
      this.grayLayerTarget.style.display = 'block';
      const operationtypesnomedtext = document.querySelector("input[name=operationtypesnomedtext");
      const operationtypesnomed = document.querySelector("input[name=operationtypesnomed");
      const selector = document.getElementById('procedure-list_selector')

      operationtypesnomedtext.value = "";
      operationtypesnomed.value = "";
      selector.innerHTML = 'Select please'

      const operationdate = document.querySelector("input[name=operationdate");
      operationdate.value = "";
      
      const bodyside = document.querySelector("select[name=bodyside");
      bodyside.value = "";

    }
  }

  setRequireProcedureState(){
    const input = document.querySelector("input[name=require_procedure")

    if(input != null){
      const switcher = document.getElementById('flexSwitchCheckChecked')
      switcher.checked = input.value == '1' ? true : false
    }
  }

  addGsmCountryInput(e){
    //set gsm code for patient
    const patientPhoneInput = e.target.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(patientPhoneInput);
    const patientGsmcountryInput = e.target.querySelectorAll('input[name=gsmcountrycode]')[0]
    patientGsmcountryInput.value = iti.selectedCountryData.dialCode
    //set gsm code for caregiver
    const caregiverPhoneInput = e.target.getElementsByClassName('phone-input')[1]
    const iti2 = window.intlTelInputGlobals.getInstance(caregiverPhoneInput);
    const caregiverGsmCountryInput = e.target.querySelectorAll('input[name=caretakergsmcountrycode]')[0]
    caregiverGsmCountryInput.value = iti2.selectedCountryData.dialCode
  }

  specialityChanged(e){
    this.setOperationTypes(e.target, true)
  }

  setOperationTypes(element, resetCarePlan){
    const selector = this.carePlanSelectorTarget;
    const selectedSpecialty = element.value

    let html = ['ent', 'to', 'gs','obg'].includes(selectedSpecialty) ? this.carePlanOptionsValue[0].outerHTML : ""
    this.carePlanOptionsValue.forEach(el => {
      switch(selectedSpecialty){
        case "ent":
          if(['ot_ear', 'ot_nose', 'ot_throat'].includes(el.value)){
            html += el.outerHTML
          }
        break;
        case "gs":
          if(['ot_gs', 'ot_colorectal'].includes(el.value)){
            html += el.outerHTML
          }
        break;
        case "to":
          if(['ot_knee', 'ot_hip', 'ot_fa', 'ot_spine'].includes(el.value)){
            html += el.outerHTML
          }
        break;
        case "other":
          if(['ot_other'].includes(el.value)){
            html += el.outerHTML
          }
        break;
        case "obg":
          if(['ot_obstetrics', 'ot_gynaecology'].includes(el.value)){
            html += el.outerHTML
          }
        break;
      }
    })
    selector.innerHTML = html
  }

  nhsEventListener(button){
    this.nhsInputTarget.addEventListener('keyup', (e) => {
        button.disabled = e.target.value.length == 10 ? false : true
    })
  }

  async searchNHS(){
    this.nhsErrorTarget.innerHTML = ''
    this.firstNameTarget.value = ''
    this.lastNameTarget.value = ''
    this.dateOfBirthTarget._flatpickr.setDate(null)
    this.genderTargets.forEach(item => item.checked = false)

    document.getElementById('search-nhs-icon').style.display = 'none'
    document.getElementById('loading-nhs-icon').style.display = 'block'

    await fetch('/patients/nhs_details?nhsnumber='+this.nhsInputTarget.value, {
      method : "GET",
      headers: {
        'Accept' : 'application/json'
      }
    })
    .then(response => response.text())
    .then(response => JSON.parse(response))
    .then(data => {
      const name = data.name.find(item => item.use == 'usual')
      const firstName = name?.given[0]
      const lastName = name?.family
      const dob = data.birthDate
      const gender = data.gender == 'male' ? 1 : (data.gender == 'female' ? 2 : 3); 

      if(data.id.trim() != this.nhsInputTarget.value.trim()){
        this.nhsErrorTarget.innerHTML = this.nhsMismatchErrorMsgValue.replace('old_nhs', this.nhsInputTarget.value).replace('new_nhs', data.id)
        this.nhsInputTarget.value = data.id
      }

      this.firstNameTarget.value = firstName
      this.lastNameTarget.value = lastName
      this.dateOfBirthTarget._flatpickr.setDate(new Date(dob))

      this.genderTargets.forEach(item => {
        item.checked = false
        if(Number(item.value) == gender){
          item.checked = true
        }
      })
    }).catch(() => {
      this.nhsErrorTarget.innerHTML = this.nhsErrorMsgValue
    })

    document.getElementById('search-nhs-icon').style.display = 'block'
    document.getElementById('loading-nhs-icon').style.display = 'none'
  }
}
