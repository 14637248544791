import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="patient-chat"
export default class extends Controller {

  static targets = ["attachmentInput"]

  static values = {
    page: {
      type: Number,
      default: 1
    },
    noMoreMessages: {
      type: Boolean,
      default: false
    }
  }

  connect() {    
    this.scrollDown();
    this.markAsRead()

    document.addEventListener('turbo:before-stream-render', (e) => {
      if(e.detail.newStream.target == 'comms_messages'){
        setTimeout(() => {
          if(e.target.action != 'prepend') this.scrollDown();
          if(e.detail.newStream.innerHTML.indexOf('justify-content-end') == -1) this.markAsRead();
        }, 200)
      }
    })

    const chatBody = document.getElementById('chat-body')
    chatBody.addEventListener('scroll', () => {
      if (chatBody.scrollTop == 0) {
        this.loadMoreMessages()
    } 
    })
  }

  loadMoreMessages(){
    if(this.noMoreMessagesValue) return;
    
    const patient_id = this.element.getAttribute('data-patient-id')
    const csrf = document.querySelector('meta[name="csrf-token"]')
    const nextPage = this.pageValue + 1;
    fetch('/patients/'+patient_id+'/load_more_messages?page='+nextPage, {
        method : "get",
        headers: {
          'X-CSRF-Token': csrf.content,
          'Accept': 'text/vnd.turbo-stream.html'
        }
    })
    .then(r => r.text())
    .then(html => {

      //if response is empty disable pulling of more messages
      if(html == '<turbo-stream action="prepend" target="comms_messages"><template></template></turbo-stream>'){
        this.noMoreMessagesValue = true
      }
      this.pageValue = nextPage;
      const heightBefore = document.getElementById('comms_messages').offsetHeight
      Turbo.renderStreamMessage(html)

      setTimeout(() => {
        const heightAfter = document.getElementById('comms_messages').offsetHeight
        
        const chatBody = document.getElementById('chat-body')
        chatBody.scrollTop = heightAfter - heightBefore
        
      }, 1)
      
    })
  }

  toggleFileUpload(){
    this.attachmentInputTarget.click();
  }

  sendMessage(e){
    const messageInput = document.getElementById('patient-chat-message-input')
    if(messageInput.value.trim() == "") e.preventDefault();
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      which: 13,
      keyCode: 13,
    });
    setTimeout(() => {
      this.clearAfterEnter(event)
    }, 100)
  }

  scrollDown(){
    const chatBody = document.getElementById('chat-body')
    chatBody.scrollTop = chatBody.scrollHeight;
  }

  clearAfterEnter(event) {
    if (event.key === "Enter") {
      const messageInput = document.getElementById('patient-chat-message-input')
      messageInput.value = ""
    }
  }

  markAsRead(){
    const patient_id = this.element.getAttribute('data-patient-id')
    const csrf = document.querySelector('meta[name="csrf-token"]')
    fetch('/mark_as_read', {
        method : "post",
        headers: {
          'X-CSRF-Token': csrf.content,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          patient_id: patient_id
        })
    })
  }
}
