import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="anaesthetic-assessment"
export default class extends Controller {

  static values = {
    step: Number
  }

  connect() {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.checkSectionsCompletion()
            }
        });
    });

    const inputs = document.querySelectorAll('input')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.checkSectionsCompletion()

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkSection1(){
    const buttonInputs = document.querySelectorAll('#anaesthetic-assessment-1 input[type="hidden"]')
    const radioInputs = document.querySelectorAll('#anaesthetic-assessment-1 input[type="radio"]')
    const indicator = document.querySelector('#anaesthetic-assessment-1 .completion-indicator')

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    const radioChecked = Array.from(radioInputs).find(input => input.checked)

    if(!radioChecked){
      allChecked = false;
    }

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkSection2(){
    const buttonInputs = document.querySelectorAll('#anaesthetic-assessment-2 input[type="hidden"]')
    const indicator = document.querySelector('#anaesthetic-assessment-2 .completion-indicator')

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkSection3(){
    const buttonInputs = document.querySelectorAll('#anaesthetic-assessment-3 input[type="hidden"]')
    const indicator = document.querySelector('#anaesthetic-assessment-3 .completion-indicator')

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkSection4(){
    const buttonInputs = document.querySelectorAll('#anaesthetic-assessment-4 input[type="hidden"]')
    const indicator = document.querySelector('#anaesthetic-assessment-4 .completion-indicator')

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkTotalScore(){
    const buttonInputs = document.querySelectorAll('#anaesthetic-assessment-4 input[type="hidden"]')
    const yesAnswered = Array.from(buttonInputs).filter(input => input.value == '1')

    const totalScore = document.getElementById('total-score')
    totalScore.innerHTML = yesAnswered.length

    document.querySelectorAll('.anaesthetic-assessment-total-score-box').forEach(el => el.classList.remove('active'))

    yesAnswered.forEach((el, key) => {
      const item = document.querySelector('.anaesthetic-assessment-total-score-box[data-index="'+key+'"]')
      item.classList.add('active')
    })

    const percentValues = {
      0: 9,
      1: 20,
      2: 39,
      3: 60,
      4: 78
    }

    const percentage = percentValues[yesAnswered.length]

    const circle = document.querySelector('#ponv-chart .circle')

    circle.setAttribute('stroke-dasharray', percentage+", 100")

    document.querySelector('#ponv-chart text').innerHTML = percentage+'%'
  }

  checkSectionsCompletion(){
    if(this.stepValue == 1){
      this.checkSection1()
      this.checkSection2()
      this.checkSection3()
    }else{
      this.checkSection4()
      this.checkTotalScore()
    }
  }

  addDrug(e){
    const type = e.target.getAttribute('data-type')

    const element = document.querySelectorAll('.drug.'+type)[0]
    const clone = element.cloneNode(true)
    clone.querySelector('input').value = ''

    const parent = document.getElementById(type+'_list')
    parent.appendChild(clone);
  }

  deleteDrug(e){
    const el = e.target.closest('.drug')
    el.remove()
  }
}
