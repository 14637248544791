import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-call"
export default class extends Controller {

  static client = {};

  static values = {
    patientId: {
      type: Number
    },
    client: {
      type: Object,
      default: {}
    },
    localTracks: {
      type: Object,
      default: {
        videoTrack: null,
        audioTrack: null
      }
    },
    remoteUsers: {
      type: Object,
      default: {}
    },
    options: {
      type: Object,
      default: {
        appid: null,
        channel: null,
        uid: null,
        token: null,
        device_id: null
      }
    },
    videoProfiles: {
      type: Array,
      default: [{
          label: "360p_7",
          detail: "480×360, 15fps, 320Kbps",
          value: "360p_7"
      }, {
          label: "360p_8",
          detail: "480×360, 30fps, 490Kbps",
          value: "360p_8"
      }, {
          label: "480p_1",
          detail: "640×480, 15fps, 500Kbps",
          value: "480p_1"
      }, {
          label: "480p_2",
          detail: "640×480, 30fps, 1000Kbps",
          value: "480p_2"
      }, {
          label: "720p_1",
          detail: "1280×720, 15fps, 1130Kbps",
          value: "720p_1"
      }, {
          label: "720p_2",
          detail: "1280×720, 30fps, 2000Kbps",
          value: "720p_2"
      }, {
          label: "1080p_1",
          detail: "1920×1080, 15fps, 2080Kbps",
          value: "1080p_1"
      }, {
          label: "1080p_2",
          detail: "1920×1080, 30fps, 3000Kbps",
          value: "1080p_2"
      }]
    },
    curVideoProfile: {
      type: Object,
      default: {}
    }
  }

  connect() {

    window.addEventListener('resize', () => {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const el = document.getElementById('video-call')

        if(window.innerWidth < 992){
          el.removeAttribute('data-x')
          el.removeAttribute('data-y')
          el.style.transform = ''
        }

      }, 100)
    })

    this.setupAgora()
    this.initVideoProfiles();


    document.getElementById('join-video-call')?.addEventListener('click', () => {
      this.showModal();
    })
  }

  setupAgora(){
    AgoraRTC.onAutoplayFailed = () => {
        alert("click to start autoplay!");
    }

    AgoraRTC.onMicrophoneChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            this.localTracksValue.audioTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === this.localTracksValue.audioTrack.getTrackLabel()) {
            const oldMicrophones = await AgoraRTC.getMicrophones();
            oldMicrophones[0] && this.localTracksValue.audioTrack.setDevice(oldMicrophones[0].deviceId);
        }
    }

    AgoraRTC.onCameraChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
          this.localTracksValue.videoTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === this.localTracksValue.videoTrack.getTrackLabel()) {
            const oldCameras = await AgoraRTC.getCameras();
            oldCameras[0] && this.localTracksValue.videoTrack.setDevice(oldCameras[0].deviceId);
        }
    }
  }

  async initDevices() {
      if (!this.localTracksValue.audioTrack) {
          this.localTracksValue.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
              encoderConfig: "music_standard"
          });
      }
      if (!this.localTracksValue.videoTrack) {
          this.localTracksValue.videoTrack = await AgoraRTC.createCameraVideoTrack({
              encoderConfig: this.curVideoProfileValue.value
          });
      }
  }

  async joinCall(){
    try {
        this.client = AgoraRTC.createClient({
            mode: "rtc",
            codec: 'vp8'
        });
        this.getToken().then(() => {
          this.join();
        })

    } catch (error) {
        console.error(error);
    }
  }

  async getToken(){
    return fetch('/patients/'+this.patientIdValue+'/agora_token').then(response => response.text())
    .then(response => JSON.parse(response))
    .then(data => {
      this.optionsValue.channel = data.agora_channel
      this.optionsValue.uid = data.agora_uid
      this.optionsValue.token = data.agora_token
      this.optionsValue.device_id = data.device_id
      this.optionsValue.appid = data.app_id

      const el = document.getElementById('call-notes-cahnnel-name-input')
      el.value = data.agora_channel
    })
  }

  async switchCamera(label) {
      currentCam = cams.find(cam => cam.label === label);

      // switch device of local video track.
      await this.localTracksValue.videoTrack.setDevice(currentCam.deviceId);
  }

  async switchMicrophone(label) {
      currentMic = mics.find(mic => mic.label === label);

      // switch device of local audio track.
      await this.localTracksValue.audioTrack.setDevice(currentMic.deviceId);
  }

  initVideoProfiles() {
      this.curVideoProfileValue = this.videoProfilesValue.find(item => item.label == '480p_1');
  }

  async changeVideoProfile(label) {
      this.curVideoProfileValue = this.videoProfilesValue.find(profile => profile.label === label);

      // change the local video track`s encoder configuration
      this.localTracksValue.videoTrack && (await this.localTracksValue.videoTrack.setEncoderConfiguration(curVideoProfile.value));
  }

  async join() {
      // Add an event listener to play remote tracks when remote user publishes.
      this.client.on("user-published", (user, mediaType) => {
          const id = user.uid;
          this.remoteUsersValue[id] = user
          this.subscribe(user, mediaType);
      });
      this.client.on("user-unpublished", (user, mediaType) => {
          if (mediaType === "video") {
              const id = user.uid;
              delete this.remoteUsersValue[id];
              document.getElementById(`player-wrapper-${id}`).remove();
          }
      });
      // Join the channel.
      this.optionsValue.uid = await this.client.join(this.optionsValue.appid, this.optionsValue.channel, this.optionsValue.token || null, this.optionsValue.uid || null);
      if (!this.localTracksValue.audioTrack) {
        this.localTracksValue.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
              encoderConfig: "music_standard"
          });
      }
      if (!this.localTracksValue.videoTrack) {
        this.localTracksValue.videoTrack = await AgoraRTC.createCameraVideoTrack({
              encoderConfig: this.curVideoProfileValue.value
          });
      }

      // Send necessary notifications
      var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "/video_calls", true);
      xhr.setRequestHeader("X-CSRF-Token", csrfToken);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify({"device_id": this.optionsValue.device_id, "agora_channel": this.optionsValue.channel, "agora_token": this.optionsValue.token, "agora_uid": this.optionsValue.uid}));

      // Play the local video track to the local browser and update the UI with the user ID.
      this.localTracksValue.videoTrack.play("local-player");

      // Publish the local video and audio tracks to the channel.
      await this.client.publish(Object.values(this.localTracksValue));
      console.log("publish success");
  }

  async leave() {
      for (let trackName in this.localTracksValue) {
          var track = this.localTracksValue[trackName];
          if (track) {
              track.stop();
              track.close();
              this.localTracksValue[trackName] = undefined;
          }
      }

      document.getElementById('video-call').classList.add('d-none')

      const callNotesModal = document.getElementById('call-notes')
      callNotesModal.classList.add('d-none')

      callNotesModal.querySelectorAll('textarea').forEach(el => el.value = '')

      // Remove remote users and player views.
      this.remoteUsersValue = {};
      document.getElementById("remote-playerlist").innerHTML = ''

      // leave the channel
      await this.client.leave();

      console.log("client leaves channel success");
  }

  async subscribe(user, mediaType) {
      const uid = user.uid;
      // subscribe to a remote user
      await this.client.subscribe(user, mediaType);
      console.log("subscribe success");
      if (mediaType === "video") {
          const player = `
        <div id="player-wrapper-${uid}">
          <!--<p class="player-name">remoteUser(${uid})</p>-->
          <div id="player-${uid}" class="player"></div>
        </div>
      `
          document.getElementById("remote-playerlist").innerHTML += player
          user.videoTrack.play(`player-${uid}`);
      }
      if (mediaType === "audio") {
          user.audioTrack.play();
      }
  }

  showModal(){
    const el = document.getElementById('video-call')
    el.classList.remove('d-none')
    this.joinCall()
  }

  resizeModal(){
    const el = document.getElementById('video-call')

    el.setAttribute('data-size', (el.getAttribute('data-size') == 'big' ? 'small' : 'big'));
    el.removeAttribute('data-x')
    el.removeAttribute('data-y')
    el.style.right = 0;
    el.style.left = ''
    el.style.transform = ''

  }

  async toggleMic(e){
    const newVal = !this.localTracksValue.audioTrack.enabled
    await this.localTracksValue.audioTrack.setEnabled(newVal)

    if(!newVal){
      e.target.classList.add('disabled')
    }else{
      e.target.classList.remove('disabled')
    }
  }

  async toggleVideo(e){
    const newVal = !this.localTracksValue.videoTrack.enabled
    await this.localTracksValue.videoTrack.setEnabled(newVal)

    if(!newVal){
      e.target.classList.add('disabled')
    }else{
      e.target.classList.remove('disabled')
    }

    const videoEl = document.querySelector('#clinician-window div[id^=agora-video-player-track-cam-]')

    videoEl.style.display = newVal ? 'block' : 'none'
  }
}
