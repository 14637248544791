import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="physio-calendar"
export default class extends Controller {
  connect() {
    this.exercises = JSON.parse(this.element.getAttribute('data-exercises'))
    this.currentWeek = Math.max(1, Number(this.element.getAttribute('data-week')))
    this.activeWeeks = this.getSlideRange()
    this.anyBodyPartSelected = this.checkIfAnyBodyPartSelected()
    this.draw()
    this.setupWeeks()
  }

  draw(){
    let html = this.drawWeekSelector();

    for (const [week, days] of Object.entries(this.exercises)) {
      let daysOfWeek = [];

      let endDay = Number(week) * 7;
      let startDay = Number(week) == 1 ? endDay - 7 : endDay - 6;

      for(let i = startDay; i <= endDay; i++){
        daysOfWeek.push(i)
      }

      const activeDays = Object.keys(days).filter(key => days[key].length > 0);

      html += '<div class="'+(Number(week) == this.currentWeek ? "d-flex" : "d-none")+' flex-column flex-fill weekly-exercises-container" id="physio-week-'+week+'">';
        html += '<div class="mb-3">'
          html += '<div class="dropdown position-relative w-fit-content">'
            html += '<button class="btn btn-light text-dark-blue fw-bold dropdown-toggle physio-day-picker-shadow input-border-radius px-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" '+(!this.anyBodyPartSelected ? 'disabled' : '')+'>'
              html += '<span class="fs-14px">'+(this.anyBodyPartSelected ? 'Day '+activeDays.join(', ') : '<span class="me-5">-</span>')+'</span>';
            html += '</button>'
            html += '<div class="dropdown-menu py-2 px-3 border-0 input-border-radius shadow w-100 min-w-auto">'
              daysOfWeek.forEach(day => {
                html += '<div class="d-flex w-fit-content mb-2">'
                  html += '<input class="form-check-input" type="checkbox" id="day'+day+'" value="'+day+'" '+(activeDays.map(item => Number(item)).includes(day) ? 'checked' : '')+' data-action="change->physio#dayChanged">'
                  html += '<label class="form-check-label d-block text-gray10 ms-3 fs-14px fw-500" for="day'+day+'">'
                    html += day;
                  html += '</label>'
                html += '</div>'
              })
            html += '</div>'
          html += '</div>'
        html += '</div>'

        html += '<div class="flex-fill position-relative">'
          html += '<div class="position-xl-absolute h-100 w-100 overflow-y-auto-thin-scrollbar pe-2">'
            if(Object.values(days).length > 0){
              for (const [day, exercises] of Object.entries(days)) {     
                if(Object.values(exercises).length > 0){
                  html += '<div class="mb-3">'
                    html += '<div class="fs-14px text-dark-blue fw-bold mb-3">Day '+day+'</div>'
                    Object.values(exercises).forEach(exercise => {
                      html += '<div class="mb-2">'
                        html += this.drawExercise(exercise, week, day)
                      html += '</div>'
                    })
                  html += '</div>'
                }
              }
            }
          html += '</div>'
        html += '</div>'
      html += '</div>';
    }

    this.element.innerHTML = html
  }

  drawExercise(exercise, week, day){
    let html = '<div class="exercise-container d-flex p-1 position-relative">'
      if(exercise.completed == true || exercise.completed == 'true'){
        html += '<div class="gray-layer"></div>'
      }
      
      html += '<div class="exercise-thumbnail-container me-2" style="background-image:url(\''+exercise.image+'\')"></div>'
      html += '<div class="flex-fill d-flex flex-column justify-content-between py-1">'
        html += '<div class="w-100 d-flex justify-content-between align-items-start">'
          html += '<div class="d-flex">'
            html += '<img src="'+this.element.getAttribute('data-exercise-icon')+'">'
            html += '<div class="fs-14px text-primary">'+exercise.name+'</div>'
          html += '</div>'
          html += '<div>'
            html += '<div class="form-check form-switch">'
              html += '<input class="form-check-input float-unset" type="checkbox" data-action="change->physio#setExerciseEnabled" data-id="'+exercise.id+'" data-week="'+week+'" data-day="'+day+'" role="switch" value="1" '+(exercise.enabled == 'true' || exercise.enabled == true ? 'checked' : '')+'>'
            html += '</div>'
            html += '<input type="hidden" class="physio-input" name="physio['+week+']['+day+']['+exercise.id+'][enabled]" value="'+exercise.enabled+'">'
            html += '<input type="hidden" class="physio-input" name="physio['+week+']['+day+']['+exercise.id+'][sets]" value="'+exercise.sets+'">'
            html += '<input type="hidden" class="physio-input" name="physio['+week+']['+day+']['+exercise.id+'][reps]" value="'+exercise.reps+'">'
            html += '<input type="hidden" class="physio-input" name="physio['+week+']['+day+']['+exercise.id+'][completed]" value="'+exercise.completed+'">'
            html += '<input type="hidden" class="physio-input" name="physio['+week+']['+day+']['+exercise.id+'][difficulty]" value="'+(exercise.difficulty ?? null)+'">'
          html += '</div>'
        html += '</div>'
        html += '<div>'
          html += '<div class="d-flex justify-content-between">'
            html += '<div class="d-flex align-items-end">'
              html += '<div class="d-flex me-3 align-items-center">'
                html += '<img src="'+this.element.getAttribute('data-repeats-icon')+'" width="18" class="me-2">'
                html += '<div class="fs-14px text-dark-blue text-nowrap">Rep: <span id="'+week+'-'+day+'-'+exercise.id+'-reps">'+exercise.reps+'</span></div>'
              html += '</div>'
              html += '<div class="d-flex align-items-center">'
                html += '<img src="'+this.element.getAttribute('data-sets-icon')+'" width="18" class="me-2">'
                html += '<div class="fs-14px text-dark-blue text-nowrap">Sets: <span id="'+week+'-'+day+'-'+exercise.id+'-sets">'+exercise.sets+'</span></div>'
              html += '</div>'
            html += '</div>'
            html += '<div>'
              html += "<a href='javascript:;' class='edit-exercise-btn text-decoration-none px-2 "+(exercise.enabled == 'true' || exercise.enabled == true ? 'd-flex' : 'd-none')+"' id='edit-exercise-"+week+"-"+day+"-"+exercise.id+"' data-action='click->physio#showEditModal' data-week='"+week+"' data-day='"+day+"' data-exercise='"+JSON.stringify(exercise)+"'>"
                html += '<img src="'+this.element.getAttribute('data-pencil-icon')+'">'
                html += '<div class="ms-1">Edit</div>'
              html += '</a>'
            html += '</div>'
          html += '</div>'
        html += '</div>'
      html += '</div>'
    html += '</div>'

    return html
  }

  drawWeekSelector(){
    let html = '<div class="mb-3" id="small-selector">'
      html += '<div class="d-flex justify-content-between align-items-center">'
        html += '<div class="me-2">'
          html += '<a href="javascript:;" data-action="click->physio-calendar#scrollWeeksLeft">'
            html += '<img src="'+this.element.getAttribute('data-calendar-left-icon')+'">'
          html += '</a>'
        html += '</div>'
        html += '<div class="flex-fill d-flex justify-content-between">'
          Object.keys(this.exercises).forEach(week => {
            week = Number(week)
            html += '<a href="javascript:;" class="p-2 text-dark-blue physio-week-selector text-decoration-none fs-14px text-nowrap '+(week == this.currentWeek && this.anyBodyPartSelected ? 'active' : (!this.anyBodyPartSelected ? 'no-hover' : ''))+' '+((week >= this.activeWeeks[0] && week <= this.activeWeeks[1]) ? 'd-block' : 'd-none')+'" data-action="click->physio-calendar#setActiveWeek" data-week="'+week+'">Week '+week+'</a>'
          })
        html += '</div>'
        html += '<div class="ms-2">'
          html += '<a href="javascript:;" data-action="click->physio-calendar#scrollWeeksRight">'
            html += '<img src="'+this.element.getAttribute('data-calendar-right-icon')+'">'
          html += '</a>'
        html += '</div>'
      html += '</div>'
    html += '</div>'

    return html
  }

  scrollWeeksRight(){
    if(this.activeWeeks[0] == 49 || !this.anyBodyPartSelected) return;
    this.activeWeeks[0] += 4
    this.activeWeeks[1] += 4 
    this.setupWeeks()
  }

  scrollWeeksLeft(){
    if(this.activeWeeks[0] == 1 || !this.anyBodyPartSelected) return;
    this.activeWeeks[0] -= 4
    this.activeWeeks[1] -= 4 
    this.setupWeeks()
  }

  setActiveWeek(e){
    if(!this.anyBodyPartSelected) return;
    const weekSelectors = document.querySelectorAll('#small-selector .physio-week-selector')
    weekSelectors.forEach(selector => selector.classList.remove('active'))
    e.target.classList.add('active')

    const exerciseContainers = document.querySelectorAll('.weekly-exercises-container')
    exerciseContainers.forEach(item => {
      item.classList.remove('d-flex')
      item.classList.add('d-none')
    })

    const exercisesContainer = document.getElementById('physio-week-'+e.target.getAttribute('data-week'))
    exercisesContainer.classList.remove('d-none')
    exercisesContainer.classList.add('d-flex')
    
    const trigger = new CustomEvent("enable-disable-physio-search");
    window.dispatchEvent(trigger);
  }

  setupWeeks(){
    const weekSelectors = document.querySelectorAll('#small-selector .physio-week-selector')
    weekSelectors.forEach(selector => {
      const week = Number(selector.getAttribute('data-week'))
      if(week >= this.activeWeeks[0] && week <= this.activeWeeks[1]){
        selector.classList.remove('d-none')
      }else{
        selector.classList.add('d-none')
      }
    })
  }

  getSlideRange() {
      const weeksPerSlide = 4;

      const slideNumber = Math.ceil(this.currentWeek / weeksPerSlide);
      const startingWeek = (slideNumber - 1) * weeksPerSlide + 1;
      const endingWeek = slideNumber * weeksPerSlide;
      
      return [startingWeek, endingWeek];
  }

  checkIfAnyBodyPartSelected(){
    const bodyPartInputs = document.querySelectorAll("input[name='body_parts[]']")
    for(let i = 0; i < bodyPartInputs.length; i++){
      const input = bodyPartInputs[i]
      if(input.checked) return true;
    }
    return false;
  }
}
