<template>
    <div>
        <div class="alert overflow-hidden border-unset bg-white alert-dismissable alert-shadow d-flex justify-content-between mb-0 p-0" :class="{ 'shadow' : props.shadow }" role="alert">
            <div class="d-flex w-100">
                <div class="danger-alert-vertical-bar"></div>
                <div class="p-3 d-flex align-items-center w-100">
                <div class="d-flex align-items-center">
                    <img :src="getImageURL('error-message.svg')" width="20">
                </div>
                <div class="mx-3 fs-13px">
                    {{ props.message }}
                </div>
                <button type="button" class="btn-close ms-auto" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getImageURL } from '../utils/image_url_helper';
const props = defineProps(['message', 'shadow'])
</script>