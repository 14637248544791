import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"

// Connects to data-controller="patient-login"
export default class extends Controller {
  connect() {
    initIntlTelInput()
  }

  addGsmCountryInput(e){
    //set gsm code for patient
    const patientPhoneInput = e.target.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(patientPhoneInput);
    const patientGsmcountryInput = document.getElementById('gsmcountrycode')
    patientGsmcountryInput.value = iti.selectedCountryData.dialCode
  }
}
