const setLoading = (button, isSmall = false) => {
    button.disabled = true
    const spinnerClass = isSmall ? "spinner-sm" : "spinner"
    button.classList.add(spinnerClass, "spinner-light", "spinner-right")
}

const disableLoading = (button, isSmall = false) => {
    button.disabled = false
    const spinnerClass = isSmall ? "spinner-sm" : "spinner"
    button.classList.remove(spinnerClass, "spinner-light", "spinner-right")
}

export { setLoading, disableLoading }