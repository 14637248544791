import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"
// Connects to data-controller="profile"
export default class extends Controller {

  static targets = ['fileInput', 'avatarImage']

  connect() {
    initIntlTelInput();

    this.fileInputTarget.addEventListener('change', (e) => {
      const file = e.target.files[0]
      if (file) {
        this.avatarImageTarget.src = URL.createObjectURL(file)
      }
    })
  }

  triggerPhotoUpload(e){
    e.preventDefault()
    this.fileInputTarget.click()
  }

  addGsmCountryInput(){
    //set gsm code
    const phoneInput = document.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(phoneInput);
    const gsmcountryInput = document.getElementById('clinician_gsmcountry')
    gsmcountryInput.value = iti.selectedCountryData.dialCode
  }

  submitPassword(e){
    e.preventDefault();
    e.target.requestSubmit();
  }
}
