import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input";

// Connects to data-controller="clinicians"
export default class extends Controller {

  static targets = [ "clinicalTeamsCounter", "clinicalTeamsList"]

  connect() {
    initIntlTelInput()
    this.count()
  }

  count(){

    const items = this.clinicalTeamsListTarget.getElementsByTagName('input')

    let counter = 0;

    for(let i = 0; i < items.length; i++){
      if(items[i].checked) counter++;
    }

    this.clinicalTeamsCounterTarget.innerHTML = counter
  }

  addGsmCountryInput(e){
    //set gsm code for clinical team
    const phoneInput = e.target.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(phoneInput);
    const gsmCountryInput = e.target.querySelectorAll('input[name=gsmcountry]')[0]
    gsmCountryInput.value = iti.selectedCountryData.dialCode
  }
}
