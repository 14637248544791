<template>
    <div class="modal fade" id="edit-exercise-modal" data-bs-backdrop="static" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down h-100 m-0-auto">
            <div class="modal-content input-border-radius border-unset">
            <div class="modal-header border-unset mb-2">
                <a type="button" @click="physioStore.editModal.hide()" class="close-modal-custom">
                    <img :src="getImageURL('close-modal.svg')">
                </a>
            </div>
            <div class="modal-body text-start">

                <div class="mb-3">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe id="video-exercise-iframe" :src="physioStore.formData.video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
                    </div>
                </div>
                <div class="text-primary fs-20px fw-bold mb-3">{{ physioStore.formData.name }}</div>
                <div class="fs-15px text-gray2 mb-3">{{ physioStore.formData.desc }}</div>
                <div class="row">
                    <div class="col-6">
                        <label for="edit-exercise-reps" class="form-label modal-label">{{ langStore.lang.exercises.reps }}</label>
                        <input type="number" v-model="physioStore.formData.reps" class="form-control text-dark-blue">
                    </div>
                    <div class="col-6">
                        <label for="edit-exercise-sets" class="form-label modal-label">{{ langStore.lang.exercises.sets }}</label>
                        <input type="number" v-model="physioStore.formData.sets" class="form-control text-dark-blue">
                    </div>
                </div>
            </div>
            <div class="modal-footer border-unset">
                <button type="button" class="btn btn-orange px-5" :disabled="!physioStore.formData.reps || !physioStore.formData.sets" @click="save">{{ langStore.lang.views.save_changes }}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../stores/lang';
import { usePhysioStore } from '../../stores/physio';
import { getImageURL } from '../../utils/image_url_helper';

const langStore = useLangStore();
const physioStore = usePhysioStore();

const save = () => {
    physioStore.currentExercise.sets = physioStore.formData.sets;
    physioStore.currentExercise.reps = physioStore.formData.reps;

    physioStore.editModal.hide()
}
</script>