export const inputsToJson = (inputs) => {
    const result = {};

    inputs.forEach(input => {
        const name = input.name; // physio[1][1][][id]
        const value = input.value; // 5
        const keys = name.match(/([^\[\]]+)/g); // ['physio', '1', '1', '', 'id']

        let current = result;

        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                current[key] = value;
            } else {
                if (!current[key]) {
                    current[key] = keys[index + 1] === '' ? [] : {};
                }
                current = current[key];
            }
        });
    });

    return result;
}