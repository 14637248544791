import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="patient-details"
export default class extends Controller {

  static targets = ['recoveryStatusForm', 'recoveryStatusInput', 'watchCloselyForm', 'watchCloselyInput', 'isPinnedForm', 'isPinnedInput', 'dischargePatientForm', 'resendInvitationForm']

  connect() {
    const el = document.getElementById('created_msg');
    const successMessage = el && el.querySelector('#success-message');
    if(successMessage){
      const modal = new Modal(successMessage);
      modal.show();
      successMessage.addEventListener("hide.bs.modal", (e) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('show_created_msg');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
      });
    }
  }

  setRecoveryStatus(e){
    const elements = document.querySelectorAll('.dropdown-item.recovery-status')
    elements.forEach(el => el.classList.remove('active'))

    e.currentTarget.classList.add('active')

    const recoveryStatus = Number(e.currentTarget.getAttribute('data-status'))
    this.recoveryStatusInputTarget.value = recoveryStatus

    const overrideReasonContainer = document.getElementById('override-reason-container')
    if(overrideReasonContainer){
      overrideReasonContainer.classList.remove('d-none')
      return
    }

    this.recoveryStatusFormTarget.requestSubmit();
  }

  setOverrideReason(e){
    const reason = e.currentTarget.getAttribute('data-reason')
    const input = document.querySelector('input[name="override_reason"]')
    input.value = reason

    if(reason == 'other'){
      const otherReasonContainer = document.getElementById('other-reason-container')
      otherReasonContainer.classList.remove('d-none')
      return
    }

    this.submitRecoveryStatus()
  }

  submitRecoveryStatus(){
    const successMessage = document.getElementById('recovery-success-container')
    successMessage.classList.remove('d-none')

    const overrideReasonContainer = document.getElementById('override-reason-container')
    overrideReasonContainer.classList.add('d-none')

    setTimeout(() => {
      this.recoveryStatusFormTarget.requestSubmit();
    }, 2000)
    
  }

  enableSaveButton(e){
    const button = document.querySelector('.recovery-status-save-btn')
    button.disabled = e.target.value.length == 0
  }

  watchCloselyChanged(e){
    const watchClosely = e.target.checked ? 1 : 0;

    this.watchCloselyInputTarget.value = watchClosely;
    this.watchCloselyFormTarget.requestSubmit();
  }

  changeIsPinned(){
    const currentValue = this.isPinnedInputTarget.value
    const newValue = currentValue == '1' ? 0 : 1;

    this.isPinnedInputTarget.value = newValue;
    this.isPinnedFormTarget.requestSubmit()
  }

  dischargePatient(){
   this.dischargePatientFormTarget.requestSubmit();
  }

  resendInvitation(){
    this.resendInvitationFormTarget.requestSubmit();
  }

  showRecoveryTimeline(){
    const recoveryStatusDropdownContainer = document.getElementById('recovery-status-dropdown-container')
    const recoveryTimelineContainer = document.getElementById('recovery-timeline-container')

    recoveryStatusDropdownContainer.classList.add('d-none')
    recoveryTimelineContainer.classList.remove('d-none')
  }

  hideRecoveryTimeline(){
    const recoveryStatusDropdownContainer = document.getElementById('recovery-status-dropdown-container')
    const recoveryTimelineContainer = document.getElementById('recovery-timeline-container')

    recoveryStatusDropdownContainer.classList.remove('d-none')
    recoveryTimelineContainer.classList.add('d-none')
  }
}
