import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
// Connects to data-controller="patient-care-plan"
export default class extends Controller {

  static targets = [
    'week', 
    'scroller', 
    'cell', 
    'startWeek', 
    'endWeek', 
    'editButton', 
    'linkformDataButton', 
    'saveChangesButton', 
    'saveChangesModalButton', 
    'cancelChangesButton', 
    'cancelChangesModalButton', 
    'clickable', 
    'warning', 
    'editForm', 
    'tableElement'
  ]

  static values = {
    range: {
      type: Array
    },
    step: {
      type: Number
    },
    editMode: {
      type: Boolean,
      default: false
    },
    initialInputs: {
      type: Object,
      default: {}
    }
  }


  determineStep(){
    if(window.innerWidth < 992){
      this.stepValue = 7
    }else{
      this.stepValue = 14
    }
  }

  determineRange(){
    this.determineStep();
    const currentWeek = Math.min(Number(this.element.getAttribute('data-week')), 51)
    const rangeStart = currentWeek * 7
    this.rangeValue = [rangeStart, rangeStart+this.stepValue]
  }

  connect() {
    this.renderTemplate();   

    window.addEventListener('resize', () => {
      this.determineRange();
      this.renderCells();
      this.calculateWeek();
    })

    window.addEventListener("turbo:before-visit", (e) => {
      if(this.unsavedChanges() && e.detail.url != window.location.href){
        e.preventDefault()
        this.showConfirmationModal();
      }
    })

    // set initial state of checkboxes so later we can detect changes on submit
    this.initialInputsValue = {...this.getInputsData()}
    Turbo.cache.exemptPageFromPreview()
  }

  renderCells(){
    const cells = document.querySelectorAll('div.cell')
    cells.forEach(cell => {
      const value = Number(cell.getAttribute('data-cell'))
      if(value >= this.rangeValue[0] && value <= this.rangeValue[1]){
        cell.style.display = 'block'
      }else cell.style.display = 'none'
    })
  }

  calculateWeek(){
    const start = this.rangeValue[0] / 7 + 1;
    const end = this.rangeValue[1] / 7;

    this.startWeekTarget.innerHTML = Math.ceil(Math.max(start, 1))
    this.endWeekTarget.innerHTML = Math.floor(Math.max(end, 2))
  }

  nextSlide(){
    const start = this.rangeValue[0] + 7;
    const end = this.rangeValue[1] + 7;
    
    if(end > 372) return false;

    this.rangeValue = [start, end];
    this.renderCells();
    this.calculateWeek();
  }

  prevSlide(){
    const start = this.rangeValue[0] - 7;
    const end = this.rangeValue[1] - 7;

    if(start < 0) return false;

    this.rangeValue = [start, end];
    this.renderCells();
    this.calculateWeek();
  }

  select(e){
    const editable = this.element.getAttribute('data-editable')
    if(editable == 'true'){
      if(!this.editModeValue) return false;
    }
    const input = e.currentTarget.getElementsByTagName('input')[0]
    input.checked = !input.checked
  }

  unsavedChanges(){
    const initialInputs = this.initialInputsValue;
    const currentInputs = this.getInputsData();
    return JSON.stringify(initialInputs) !== JSON.stringify(currentInputs)
  }

  enableEdit(e){
    e.preventDefault();
    this.editModeValue = true;
    this.editButtonTarget.style.display = 'none';
    this.linkformDataButtonTarget.style.display = 'none';
    this.saveChangesButtonTarget.style.display = 'block';
    this.cancelChangesButtonTarget.style.display = 'block';
    this.clickableTargets.forEach(item => item.classList.remove('cursor-pointer-unset'))
    this.warningTarget.style.display = 'block'
    const toHide = this.element.querySelectorAll('.hidable')
    toHide.forEach(item => item.style.opacity = 0.5)
  }

  saveChanges(e){
    e.preventDefault();

    const initialInputs = this.initialInputsValue;
    const currentInputs = this.getInputsData();

    const data = {
      add: {},
      remove: {}
    }
    for (const [key, value] of Object.entries(initialInputs)) {
      const removedValues = value.filter(item => !currentInputs[key].includes(item))
      const addedValues = currentInputs[key].filter(item => !value.includes(item))
      data.add[key] = addedValues
      data.remove[key] = removedValues
    }

    const formData = this.obj2FormData(data)

    this.cancelChangesButtonTarget.disabled = true
    this.cancelChangesModalButtonTarget.disabled = true
    this.saveChangesButtonTarget.disabled = true
    this.saveChangesModalButtonTarget.disabled = true
    this.saveChangesButtonTarget.classList.add("spinner-sm", "spinner-light", "spinner-right")
    this.saveChangesModalButtonTarget.classList.add("spinner", "spinner-light", "spinner-right")
    
    const url = this.editFormTarget.action;
    fetch(url, {
        headers: {
          'X-CSRF-Token': this.getCsrfToken()
        },
        method : "PUT",
        body: formData

    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      this.initialInputsValue = {...this.getInputsData()}
    })
  }

  obj2FormData(obj, formData = new FormData()){

    this.formData = formData;

    this.createFormData = function(obj, subKeyStr = ''){
        for(let i in obj){
            let value          = obj[i];
            let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;

            if(typeof(value) === 'string' || typeof(value) === 'number'){

                this.formData.append(subKeyStrTrans, value);

            } else if(typeof(value) === 'object'){

                this.createFormData(value, subKeyStrTrans);

            }
        }
    }

    this.createFormData(obj);

    return this.formData;
}

  cancelChanges(e){
    e.preventDefault();
    const button = this.cancelChangesButtonTarget
    const url = button.getAttribute('data-url')
    this.getLinkformData(url)
    this.initialInputsValue = {...this.getInputsData()}
  }

  showSuccessModal(){
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    modalBackdrop?.remove()  
    const el = document.getElementById('update-care-plan-success-modal')
    if(el){
      const modal = new Modal(el)
      modal?.show()
    }
  }

  renderTemplate(){
    this.generate(JSON.parse(this.element.getAttribute('data-data')))
  }

  
  generate(data){

    const currentDay = this.element.getAttribute('data-current-day')
    const failedImage = this.element.getAttribute('data-failed-image')
    const completedImage = this.element.getAttribute('data-completed-image')
    const pendingImage = this.element.getAttribute('data-pending-image')
    const patientId = this.element.getAttribute('data-id')

    if(data == null) return;

    let html = '<div class="d-flex">';
      html += '<div>';
        html += '<div class="text-nowrap border-top py-2 px-3 fs-13px text-dark-blue border-top"><div class="h-30px">Recovery Day</div></div>';
        data.templates.forEach((item, key) => html += '<div class="text-nowrap h-50px d-flex align-items-center border-top fs-13px px-3 '+( key%2 == 0 ? 'even' : 'odd') +'">'+item[0]+'</div>')
      html += '</div>';

      html += '<div class="flex-fill">';
        html += '<div data-patient-care-plan-target="scroller">';
          html += '<div class="d-flex">';
            for(let i = 0; i <= 365; i++){
              html += '<div class="care-plan-cell py-2 border-top text-center cell fs-13px border-start text-dark-blue '+(currentDay != null && Number(currentDay) == i ? 'today' : '')+'" data-cell="'+i+'"><div>'+i+'</div></div>';
            }
          html += '</div>';
          data.templates.forEach((item, key) => {

            html += '<div class="d-flex">';
              for(let i = 0; i <= 365; i++){
                const isPast = currentDay != null && Number(currentDay) >= i
                const isToday = Number(currentDay) == i
                //check if activity was assigned to user on this date
                const wasAssigned = typeof data.schedule[item[1]].find(el => el[0] == i) != 'undefined'
                const completedAssignment = data.schedule[item[1]].find(el => el[0] == i)?.at(1) == 1

                // on patient creation there is array of integers - on update there is array of arrays 
                const element = Array.isArray(data.schedule[item[1]][0]) ? data.schedule[item[1]].map(_ => _[0]) : data.schedule[item[1]]
                
                if(!isPast){

                  html += '<div class="care-plan-cell '+ (key%2 == 0 ? 'even' : 'odd') +' h-50px text-center border-top border-start cell position-relative" data-cell="'+i+'" data-action="click->patient-care-plan#select">';
                    html += '<input type="checkbox" name="activities['+item[1]+'][]" value="'+i+'" '+(element.includes(i) ? "checked" : "")+' class="care-plan-checkbox-input d-none">';
                    html += '<div class="care-plan-cell-checkbox"></div>';
                  html += '</div>';
                }else{
                  html += '<div class="care-plan-cell '+ (key%2 == 0 ? 'even' : 'odd') +' h-50px text-center border-top border-start cell position-relative" data-cell="'+i+'">';
                  if(wasAssigned){  
                    let image = completedAssignment ? completedImage : failedImage
                    if (isToday) {
                      image = completedAssignment ? completedImage : pendingImage
                    }

                    let dataUrl = ""
                    if(completedAssignment){
                      dataUrl = data.schedule[item[1]].find(el => el[0] == i)?.at(2)
                      if(dataUrl == ""){
                        const week = Math.ceil(i / 7)
                        dataUrl = '/patients/'+patientId+'/media#week'+week
                      }
                    }

                    html += '<div class="absolute-center hidable '+(completedAssignment ? 'cursor-pointer' : '')+'" data-url="'+dataUrl+'" data-action="click->pdf#showPdfModal">';
                      html += '<img src="'+image+'">';
                    html += '</div>';
                  }  
                  html += '</div>';
                }
              }
            html += '</div>';
          })
        html += '</div>';
      html += '</div>'

    html += '</div>';

    this.tableElementTarget.innerHTML = html

    this.determineRange();
    this.renderCells();
    this.showSuccessModal();
    this.calculateWeek();
  }

  showConfirmationModal(){
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
      modalBackdrop?.remove()  
      const el = document.getElementById('confirm-changes-modal')
      const modal = new Modal(el)
      modal.show()
  }

  getLinkformData(url){ 
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  getTurboStreamFromUrl(){
    const button = this.linkformDataButtonTarget
    const url = button.getAttribute('data-url')
    this.getLinkformData(url)
  }

  getCsrfToken(){
    return document.querySelector('meta[name="csrf-token"]').content
  }

  getInputsData(){
    const inputs = this.editFormTarget.querySelectorAll("input")
    const data = {}
    for(let i = 0; i < inputs.length; i++){
      let name = inputs[i].name

      if(name == 'authenticity_token' || name == '_method') continue;

      name = name.split('[')[1].slice(0, -1)

      if(typeof data[name] == 'undefined'){
        data[name] = inputs[i].checked ? [inputs[i].value] : []
      }else{
        if(inputs[i].checked){
          data[name].push(inputs[i].value)
        }
      }
    }
    return data
  }
}