import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="auth"
export default class extends Controller {
  connect() {
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    modalBackdrop?.remove()  
    const el = document.getElementById('2fa-modal')
    const modal = new Modal(el)
    modal.show();

    setTimeout(() => {
      const tokenInput = document.getElementById('clinician_token')
      tokenInput.focus();
    }, 100)
    
  }
}
