import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  constructor(element) {
    super(element);
    this.isOpenValue = false; // Default value for isOpen
  }
  connect() {
    // Get references to the open and close buttons
    this.eyeIcon = this.element.querySelector('.show_password');
    // Add event listeners to the buttons
    this.eyeIcon.addEventListener('click', this.showPwd.bind(this));
  }

  showPwd() {
    // Open the sidebar by setting its width to 100%
    this.passwordInput = this.element.querySelector('.clinician_password');
    this.passwordInput.type = this.passwordInput.type === "password" ? "text" : "password";
    // Toggle between open-eye and closed-eye icons
    if (this.isOpenValue) {
      this.eyeIcon.classList.remove('open')
      this.eyeIcon.classList.add('closed')
      this.isOpenValue = false;
    } else {
      this.eyeIcon.classList.remove('closed')
      this.eyeIcon.classList.add('open')
      this.isOpenValue = true;
    }
  }
}
