import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="flatpickr"
export default class extends Controller {
    connect() {

        const altFormat = 'd/m/Y' 

        let datePicker = flatpickr(".fp_date", {
          locale: {
              firstDayOfWeek: 1
          },
          dateFormat: 'd/m/Y',
          altInput: true,
          altFormat,
          allowInput: true,
        })

        if(typeof datePicker.config != 'undefined') datePicker = [datePicker];

        datePicker?.forEach(element => {
          element._input.addEventListener('input', (event) => {
              const value = element._input.value
              const parsedDate = element.parseDate(value, altFormat)
              const formattedDate = element.formatDate(parsedDate, altFormat)
              if(value === formattedDate) element.setDate(value, true, altFormat)
            }, true)
        })


        let timePicker = flatpickr(".fp_time", {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true
        })
    }
}
