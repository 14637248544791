import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="patient-linkform"
export default class extends Controller {

  static targets = ['videoLive', 'videoPreview', 'photoLive']

  connect() {
  }

  async goToVideo () {
    const videoExplainSection = document.getElementById('video-explain')
    const videoSection = document.getElementById('take-video')
    const disappearingVideoDesc = document.getElementById('disappearing-video-instruction')
    const buttonStart = document.querySelector('#videoButtonStart')
    const buttonStop = document.querySelector('#videoButtonEnd')
    const takeVideoSection = document.getElementById('take-video')
    const videoPreviewSection = document.getElementById('preview-video')

    videoExplainSection.classList.add('d-none')
    videoSection.classList.remove('d-none')

    await this.setStream('environment', 'video')

    setTimeout(() => {
      disappearingVideoDesc?.remove()
    }, 3000)
  
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        var options = {mimeType: 'video/webm; codecs=vp9'};
    } else  if (MediaRecorder.isTypeSupported('video/webm')) {
        var options = {mimeType: 'video/webm'};
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        var options = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
    } else {
        console.error("no suitable mimetype found for this device");
    }
    
    this.mediaRecorder = new MediaRecorder(this.stream, options);
  
    buttonStart.addEventListener('click', () => this.startVideoRecording())
  
    buttonStop.addEventListener('click', () => this.stopVideoRecording())
  
    this.mediaRecorder.addEventListener('dataavailable', async event => {
      this.video = event.data

      this.videoPreviewTarget.src = await URL.createObjectURL(event.data)

      takeVideoSection.classList.add('d-none')
      videoPreviewSection.classList.remove('d-none')
    })
  }

  async setStream(mode, type) {

    if(this.stream){
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    const constraints = {
      video: {
        facingMode: mode
      },
      audio: type == 'video'
    }

    this.cameraMode = mode

    const target = type == 'video' ? this.videoLiveTarget : this.photoLiveTarget;
    
    this.stream = await navigator.mediaDevices.getUserMedia(constraints)

    target.srcObject = null
    target.srcObject = this.stream
  }

  async switchVideoCamera(){
    await this.setStream(this.cameraMode == 'user' ? 'environment' : 'user', 'video')
  }

  async switchPhotoCamera(){
    await this.setStream(this.cameraMode == 'user' ? 'environment' : 'user', 'photo')
  }

  async startVideoRecording(){

    if(this.startInterval) return;

    const buttonStart = document.querySelector('#videoButtonStart')
    const buttonStop = document.querySelector('#videoButtonEnd')
    const videoStartCounter = document.getElementById('video-start-counter')
    const videoDurationCounter = document.getElementById('video-duration-counter')
    
    // show video start counter
    videoStartCounter.classList.remove('d-none')

    //count from 3 to 0 backwards
    await new Promise(resolve => {
      this.startInterval = setInterval(() => {
        const seconds = Number(videoStartCounter.innerHTML)
        if(seconds > 1){
          videoStartCounter.innerHTML = seconds - 1
        }else {
          clearInterval(this.startInterval)
          delete this.startInterval
          resolve()
        }
      }, 1000)
    })

    // hide video start counter
    videoStartCounter.classList.add('d-none')
    // revert video start coutner HTML to 3
    videoStartCounter.innerHTML = 3

    // show video duration counter
    videoDurationCounter.classList.remove('d-none')
    // set video duration placeholder 
    videoDurationCounter.innerHTML = 'Video recording starting...'

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()  
      }, 1000)
    })

    // start recording video
    this.mediaRecorder.start()
    buttonStart.classList.add('d-none')
    buttonStop.classList.remove('d-none')
    
    videoDurationCounter.innerHTML = 0
    this.durationInterval = setInterval(() => {
      const seconds = Number(videoDurationCounter.innerHTML);

      if(seconds < 10){
        videoDurationCounter.innerHTML = seconds + 1;
      }else{
        this.stopVideoRecording();
      }
    }, 1000)
  }

  stopVideoRecording(){
    const buttonStart = document.querySelector('#videoButtonStart')
    const buttonStop = document.querySelector('#videoButtonEnd')
    const videoDurationCounter = document.getElementById('video-duration-counter')

    this.mediaRecorder.stop()
    buttonStop.classList.add('d-none')
    buttonStart.classList.remove('d-none')
    videoDurationCounter.classList.add('d-none')
    clearInterval(this.durationInterval)
  }

  playVideo(e){
    this.videoPreviewTarget.play();
    e.target.classList.add('d-none')
    this.videoPreviewTarget.addEventListener('ended', () => {
      e.target.classList.remove('d-none')
    })
  }

  retakeVideo(){
    const takeVideoSection = document.getElementById('take-video')
    const videoPreviewSection = document.getElementById('preview-video')
    takeVideoSection.classList.remove('d-none')
    videoPreviewSection.classList.add('d-none')
  }

  async goToPhoto(){
    const photoExplainSection = document.getElementById('photo-explain')
    const photoSection = document.getElementById('take-photo')

    photoExplainSection.classList.add('d-none')
    photoSection.classList.remove('d-none')

    await this.setStream('user', 'photo')
  }

  takePhoto(){
    const takePhotoSection = document.getElementById('take-photo')
    const previewPhotoSection = document.getElementById('preview-photo')

    const canvas = document.createElement('canvas');
    canvas.width = this.photoLiveTarget.videoWidth
    canvas.height = this.photoLiveTarget.videoHeight

    canvas.getContext('2d').drawImage(this.photoLiveTarget, 0, 0, canvas.width, canvas.height);
    
    let imageDataUrl = canvas.toDataURL('image/jpeg');

    takePhotoSection.classList.add('d-none')
    previewPhotoSection.classList.remove('d-none')

    const img = previewPhotoSection.getElementsByTagName('img')[0]
    img.src = imageDataUrl
    this.photo = imageDataUrl
  }

  retakePhoto(){
    const takePhotoSection = document.getElementById('take-photo')
    const previewPhotoSection = document.getElementById('preview-photo')

    takePhotoSection.classList.remove('d-none')
    previewPhotoSection.classList.add('d-none')
  }

  openImageUpload(){
    const input = document.getElementById('photoUpload')
    input.click()
  }

  uploadPhoto(e){
    const imageDataUrl = URL.createObjectURL(e.target.files[0])

    const takePhotoSection = document.getElementById('take-photo')
    const previewPhotoSection = document.getElementById('preview-photo')

    takePhotoSection.classList.add('d-none')
    previewPhotoSection.classList.remove('d-none')

    const img = previewPhotoSection.getElementsByTagName('img')[0]
    img.src = imageDataUrl
    this.photo = imageDataUrl
  }

  next(){
    const activeSlide = document.querySelector('.linkform-slide.active')
    const nextElement = activeSlide.nextElementSibling
    const nextSlidePresent = nextElement?.classList.contains('linkform-slide')
    
    if(nextSlidePresent){
      activeSlide.classList.remove('active')
      nextElement.classList.add('active')
    }
  }

  // sendVideo = (fileObject) => {
  //   const url = 'https://api.cloudinary.com/v1_1/postop/video/upload?upload_preset=n6glup7i'
  //   const formData = new FormData();
  //   formData.append("file", fileObject);
  //   formData.append("upload_preset", "n6glup7i");

  //   const request = new XMLHttpRequest();
  //   // request.onreadystatechange = function () {
  //   //   if (request.readyState === 4) {
  //   //     if (request.status === 200) resolve(request);
  //   //     else reject(request);
  //   //   }
  //   // };
  //   request.upload.onloadstart = function () {
  //     console.log("video upload started...");
  //   };
  //   request.upload.onprogress = function (event) {
  //     console.log(
  //       "video upload Progress " +
  //         Math.round((event.loaded / event.total) * 100) +
  //         "%"
  //     );
  //     // Animated.timing(percentcomplete, {
  //     //   toValue: Math.round((event.loaded / event.total) * 100),
  //     //   easing: Easing.quad,

  //     //   duration: 500,
  //     //   useNativeDriver: true,
  //     // }).start();
  //   };
  //   request.upload.onload = function () {
  //     console.log("progress-about-to-end");
  //   };
  //   request.upload.onload = function () {
  //     console.log("video upload ended. Getting file URL.");
  //   };
  //   // request.upload.onerror = reject;
  //   // request.upload.onabort = reject;
  //   request.open("POST", url);
  //   request.send(formData);
  // }
}
