<template>
    <div class="p-4 text-start">
        <h6 class="text-dark-blue mb-1 mt-2 fs-15px">{{ langStore.lang.views.caregiver_details_title }}</h6>
        <div class="fs-13px text-gray mb-4">{{ langStore.lang.views.caregiver_desc }}</div>

        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label for="caretakerfirstname" class="form-label modal-label">{{ langStore.lang.views.first_name }}</label>
                <input type="text" name="caretakerfirstname" id="caretakerfirstname" v-model="patientStore.formData.caretakerfirstname" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.caretakerfirstname}" :placeholder="langStore.lang.views.enter_first_name">
                <span class="text-danger fs-12px" v-if="patientStore.errors.caretakerfirstname">{{ patientStore.errors.caretakerfirstname[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="caretakerlastname" class="form-label modal-label">{{ langStore.lang.views.last_name }}</label>
                <input type="text" name="caretakerlastname" id="caretakerlastname" v-model="patientStore.formData.caretakerlastname" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.caretakerlastname}" :placeholder="langStore.lang.views.enter_last_name">
                <span class="text-danger fs-12px" v-if="patientStore.errors.caretakerlastname">{{ patientStore.errors.caretakerlastname[0] }}</span>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label for="caretakergsm" class="form-label modal-label required">{{ langStore.lang.views.phone_number }}</label>
                <input type="text" id="caretakergsm" ref="phoneInput" v-model="patientStore.formData.caretakergsm" class="phone-input form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.caretakergsm}">
                <span class="text-danger fs-12px" v-if="patientStore.errors.caretakergsm">{{ patientStore.errors.caretakergsm[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="caretakeremail" class="form-label modal-label">{{ langStore.lang.views.email }} ({{ langStore.lang.views.optional }})</label>
                <input type="email" name="caretakeremail" id="caretakeremail" v-model="patientStore.formData.caretakeremail" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.caretakeremail}" :placeholder="langStore.lang.views.enter_email_address">
                <span class="text-danger fs-12px" v-if="patientStore.errors.caretakeremail">{{ patientStore.errors.caretakeremail[0] }}</span>
            </div>
        </div>

        <div class="text-dark-blue">{{ langStore.lang.views.you_can_skip_caregiver }}</div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useLangStore } from '../../stores/lang';
import { usePatientStore } from '../../stores/patient';

const phoneInput = ref(null)

const langStore = useLangStore();
const patientStore = usePatientStore();

defineExpose({
    phoneInput
})
</script>