import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"

// Connects to data-controller="clinical-teams"
export default class extends Controller {
  static targets = ["cliniciansSearchInput", "addedClinicians", "cliniciansForm"]

  connect() {
    initIntlTelInput()

    document.addEventListener('click', (event) => {
      const el = document.getElementById('clinicians-search1');
      const outsideClick = !el?.contains(event.target);
      if(outsideClick) this.hideDropdown();
    });
  }

  showDropdown(e){
    this.search(e)
    const el = document.getElementById('cliniciansDropdown')
    el.style.display = "block"
  }

  hideDropdown(){
    const el = document.getElementById('cliniciansDropdown')
    if(el){
      el.style.display = "none"
    }
    
  }

  search(){
    const el = document.getElementById('cliniciansDropdown')
    const elements = el.getElementsByClassName('name')
    const keyword = this.cliniciansSearchInputTarget.value.toLowerCase()

    for(let i = 0; i < elements.length; i++){
      const text = elements[i].outerText.toLowerCase()
      const row = elements[i].closest('.row')
      const ID = row.getAttribute('data-id')
      
      if(text.indexOf(keyword) == -1){
        row.style.display = "none";
      }else row.style.display = "flex";

      const addedClinician = this.checkIfClinicianAdded(ID)

      if(addedClinician){
        row.getElementsByClassName('already-in-team')[0].style.display = "block"
      }else row.getElementsByClassName('already-in-team')[0].style.display = "none"

    }
  }

  selectItem(e){
    const row = e.target.closest('.row')
    const ID = row.getAttribute('data-id')

    const initials = row.getElementsByClassName('initials-circle')[0].outerText
    const name = row.getElementsByClassName('name')[0].outerText
    const clinicianType = row.getElementsByClassName('clinician-type')[0].outerText

    const rowExists = this.checkIfClinicianAdded(ID);

    if(!rowExists){

      const tbody = this.addedCliniciansTarget.getElementsByTagName('tbody')[0];
      let html = "<tr data-id='"+ID+"'>";
      html += "<td>";
      html += "<div class='d-flex align-items-center'>";
      html += "<div class='initials-circle md fs-13px'>";
      html += initials;
      html += "</div>";
      html += "<div class='ms-3'>";
      html += "<div class='fs-15px text-dark-blue text-nowrap name'>";
      html += name;
      html += "</div>";
      html += "</div>";
      html += "</div>";
      html += "</td>";
      html += "<td class='text-dark-blue fs-15px'>";
      html += clinicianType;
      html += "</td>";
      html += "<td>";
      html += "<a href='javascript:;' data-action='click->clinical-teams#removeItem'><img src='/assets/blue-x.svg'></a>";
      html += "</td>";
      html +="</tr>";

      tbody.innerHTML += html

      tbody.querySelector('tr.no-clinicians').style.display = "none"
      this.search()

    }
  }

  removeItem(e){
    const row = e.target.closest('tr')
    row.remove();
    this.search()
    const selectedItems = this.addedCliniciansTarget.querySelectorAll('tr[data-id]');
    if(selectedItems.length == 0){
      const tbody = this.addedCliniciansTarget.getElementsByTagName('tbody')[0];
      tbody.querySelector('tr.no-clinicians').style.display = "table-row"
    }
  }

  checkIfClinicianAdded(ID){
    return document.querySelector("tr[data-id='"+ID+"']");
  }

  beforeSubmit(){
    
    const trs = this.addedCliniciansTarget.querySelectorAll('tr[data-id]');
    
    for(let i = 0; i < trs.length; i++){
      this.cliniciansFormTarget.innerHTML += "<input type='hidden' value='"+trs[i].getAttribute('data-id')+"' name='clinicians[]'>";
    }

    return true;
  }

  addGsmCountryInput(e){
    //set gsm code for clinical team
    const phoneInput = e.target.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(phoneInput);
    const gsmCountryInput = e.target.querySelectorAll('input[name=gsmcountrycode]')[0]
    gsmCountryInput.value = iti.selectedCountryData.dialCode
  }
}
