import intlTelInput from 'intl-tel-input';

const initIntlTelInput = () => {

    const inputs = document.getElementsByClassName("phone-input");

    if(inputs.length > 0){

        for(let i = 0; i < inputs.length; i++){

            if(!window.intlTelInputGlobals.getInstance(inputs[i])){

                intlTelInput(inputs[i], {
                    nationalMode: false,
                    autoInsertDialCode: true,
                    separateDialCode: true,
                    useFullscreenPopup: false,
                    initialCountry: "gb",
                    preferredCountries: ["gb"],
                    dropdownContainer: document.body,
                    showSelectedDialCode: true
                });
                
            }
        }

    }

}

export {
    initIntlTelInput
}
