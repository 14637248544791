<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.had_leaq_question_html"></div>
        <div class="d-flex justify-content-between mb-5">
            <button class="btn btn-white rio-pdq-button w-165px me-1" :class="{'active' : store.formData.had_leak === true}" @click="select(true)">{{ langStore.lang.yes_answer }}</button>
            <button class="btn btn-white rio-pdq-button w-165px ms-1" :class="{'active' : store.formData.had_leak === false}" @click="select(false)">{{ langStore.lang.no_answer }}</button>
        </div>

        <div v-if="store.formData.had_leak">
            <div class="text-dark-blue fs-24px fw-bold mb-5">{{ langStore.lang.if_yes_title }}:</div>

            <button 
                class="btn btn-white rio-pdq-button w-100 text-start ps-4 mb-3" 
                v-for="(leakType, key) in store.leakTypes" 
                :key="key" 
                :class="{'active' : store.formData.leak_type === leakType}" 
                @click="setLeakType(leakType)"
            >
                {{ langStore.lang.leak_options[key] }}
            </button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';

const store = usePdqStore();
const langStore = useLangStore();

const select = (value) => {
    store.formData.had_leak = value
    if(!value) store.nextStep();
}

const setLeakType = (value) => {
    store.formData.leak_type = value
    store.nextStep();
}
</script>