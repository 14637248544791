import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="zendesk"
export default class extends Controller {
  connect() {

    window.addEventListener('turbo:before-render', function () {
      window.zEACLoaded = undefined;
    });

    zE('webWidget', 'hide');

    zE('webWidget:on', 'close', function () {
      zE('webWidget', 'hide');
      document.querySelector('#zendesk').style.opacity = 1;
    });
  }

  openWidget() {
    zE("webWidget", "show");
    zE("webWidget", "open");
    document.querySelector("#zendesk").style.opacity = 0;
  }
}
