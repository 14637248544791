import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { inputsToJson } from "../utils/inputs_to_json"
// Connects to data-controller="physio"
export default class extends Controller {
  connect() {
    this.mode = this.element.getAttribute('data-mode')
    this.lang = JSON.parse(this.element.getAttribute('data-lang'))
    this.bodyPartInputs = document.querySelectorAll("input[name='body_parts[]']")
    document.getElementById('next-step-button')?.addEventListener('click', () => {
      this.showLoader();
    })

    this.selectedBodyParts = []
    
    this.updateSelectedBodyParts()
    this.setExercisesObserver()
    const editElModal = document.getElementById('edit-exercise-modal')
    this.editModal = editElModal ? new Modal(editElModal) : null

    editElModal.addEventListener('hidden.bs.modal',() => {
      document.getElementById('video-exercise-iframe').src = ''
    })

    const addElModal = document.getElementById('add-exercise-modal')
    this.addModal = addElModal ? new Modal(addElModal) : null

    this.exercisesUpdated = false
    this.detectClickOutside()
  }

  selectBodyPart(e){
    this.addRemoveBodyPart(e, 'add')
  }

  removeBodyPart(e){
    this.addRemoveBodyPart(e, 'remove')
  }

  async addRemoveBodyPart(e, action){
    const bodyPart = e.currentTarget.getAttribute('data-value')
    for(let i = 0; i < this.bodyPartInputs.length; i++){
        if(this.bodyPartInputs[i].value == bodyPart){
            this.bodyPartInputs[i].checked = (action == 'add');
        }
    }
    await this.updateSelectedBodyParts();
    this.getExercisesForBodyParts();
  }

  updateSelectedBodyParts(){
    const container = document.getElementById('body-part-tags-container')
    container.innerHTML = ''
    for(let i = 0; i < this.bodyPartInputs.length; i++){
        const input = this.bodyPartInputs[i]

        const indexToDelete = this.selectedBodyParts.indexOf(input.value)
        if(indexToDelete != -1) this.selectedBodyParts.splice(indexToDelete, 1);

        if(input.checked){
            const tag = '<div class="body-part-tag d-flex px-2 me-2 mb-2"><div class="me-2 text-nowrap">'+this.lang[input.value]+'</div><a href="javascript:;" data-value="'+input.value+'" data-action="click->physio#removeBodyPart" class="mt--2px"><img src="'+this.element.getAttribute('close-tag-img')+'"></a></div>';
            container.innerHTML += tag
          
            const textElement = document.getElementById(input.value+'_text')
            const dotElement = document.getElementById(input.value+'_dot')
            textElement.classList.add('fill-primary')
            dotElement.classList.add('fill-primary')

            this.selectedBodyParts.push(input.value)
        }else{
            const textElement = document.getElementById(input.value+'_text')
            const dotElement = document.getElementById(input.value+'_dot')
            textElement.classList.remove('fill-primary')
            dotElement.classList.remove('fill-primary')
        }
    }
  }

  async getExercisesForBodyParts(){
    if(this.mode == 'add'){
      this.getExercisesAddMode()
    }else{
      this.getExercisesEditMode();
    }
  }

  async getExercisesAddMode(){
    this.showLoader();

    const exerciseDays = [...new Set(this.getSelectedDaysForPhysio())]
    let physioInputs = this.getPhysioInputs();
    if(typeof physioInputs == 'undefined') physioInputs = {};
    const currentWeek = this.getCurrentWeek()
    const csrf = document.querySelector('meta[name="csrf-token"]')

    await fetch('/physio/template', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        body_parts: this.selectedBodyParts,
        exercise_days: exerciseDays,
        physio: physioInputs,
        week: currentWeek
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))
    this.hideLoader()
    setTimeout(() => {
      this.enableDisableSearch()
    }, 1000)
  }

  async getExercisesEditMode(){
    this.showLoader();

    const exerciseDays = JSON.parse(document.getElementById('exercise-days').value)
    let physioInputs = this.getPhysioInputs();
    if(typeof physioInputs == 'undefined') physioInputs = {};
    const patient_id = this.element.getAttribute('data-patient-id')
    const csrf = document.querySelector('meta[name="csrf-token"]')
    const currentWeek = this.getCurrentWeek() ?? 1

    await fetch('/patients/'+patient_id+'/exercises?week='+currentWeek, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        body_parts: this.selectedBodyParts,
        exercise_days: exerciseDays,
        physio: physioInputs,
        week: currentWeek
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))
    this.hideLoader()
    this.enableDisableSearch()
  }

  getSelectedDaysForPhysio(){
    const checkboxes = document.querySelectorAll('input[type="checkbox"][name*="activities"][name*="physio"]');
    // Convert the NodeList to an array (optional, if you need array methods)
    const checkboxesArray = Array.from(checkboxes);
    return checkboxesArray.filter(item => item.checked).map(item => item.value)
  }

  getPhysioInputs(){
    const physioInputs = document.querySelectorAll('.physio-input')
    const physioInputsArray = Array.from(physioInputs);
    return inputsToJson(physioInputsArray).physio
  }

  dayChanged(e){
    if(this.mode == 'add'){
      const checkbox = document.querySelector('input[type="checkbox"][name*="physio"][value="'+e.target.value+'"');
      checkbox.checked = e.target.checked
    }else{
      const exercises = JSON.parse(document.getElementById('exercise-days').value)
      if(!e.target.checked){
        const index = exercises.indexOf(Number(e.target.value))
        exercises.splice(index, 1)
      }else{
        exercises.push(Number(e.target.value))
      }
      
      document.getElementById('exercise-days').value = JSON.stringify(exercises)
    }
    
    this.getExercisesForBodyParts()
  }

  setExerciseEnabled(e){
    const week = e.target.getAttribute('data-week')
    const day = e.target.getAttribute('data-day')
    const id = e.target.getAttribute('data-id')
    const input = document.querySelector('input[name="physio['+week+']['+day+']['+id+'][enabled]"]')
    input.value = e.target.checked

    const editExerciseButton = document.getElementById('edit-exercise-'+week+'-'+day+'-'+id)

    editExerciseButton.style.cssText = 'display:'+(e.target.checked ? 'flex!important' : 'none!important');
  }


  showLoader(){
    const loader = document.getElementById('physio-loader')
    loader.style.display = 'block'
  }

  hideLoader(){
    const loader = document.getElementById('physio-loader')
    loader.style.display = 'none'
  }

  // show default exercises when physio screen first loaded
  setExercisesObserver(){
    const physioStepScreen = document.getElementById('physio-plan')

    const targetNode = document.getElementById("physio_exercises");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if(physioStepScreen?.classList.contains('d-block') && !this.exercisesUpdated){
          this.getExercisesForBodyParts()
          this.exercisesUpdated = true
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  }

  showEditModal(e){
    const day = e.currentTarget.getAttribute('data-day')
    const week = e.currentTarget.getAttribute('data-week')
    const exercise = JSON.parse(e.currentTarget.getAttribute('data-exercise'))

    document.querySelector('#edit-exercise-modal #edit-exercise-id').value = exercise.id
    document.querySelector('#edit-exercise-modal #edit-exercise-week').value = week
    document.querySelector('#edit-exercise-modal #edit-exercise-day').value = day
    document.querySelector('#edit-exercise-modal #edit-exercise-modal-name').innerHTML = exercise.name
    document.querySelector('#edit-exercise-modal #edit-exercise-modal-desc').innerHTML = exercise.desc
    document.querySelector('#edit-exercise-modal #edit-exercise-reps').value = exercise.reps
    document.querySelector('#edit-exercise-modal #edit-exercise-sets').value = exercise.sets
    document.querySelector('#video-exercise-iframe').src = exercise.video

    this.editModal.show()
  }

  hideEditModal(e){
    e?.stopPropagation()
    this.editModal.hide()
  }

  saveExerciseChanges(){
    console.log('test')
    const repsInput = document.querySelector('#edit-exercise-modal #edit-exercise-reps')
    const setsInput = document.querySelector('#edit-exercise-modal #edit-exercise-sets')
    repsInput.classList.remove('is-invalid')
    setsInput.classList.remove('is-invalid')

    if(repsInput.value.trim() == ''){
      repsInput.classList.add('is-invalid')
    }
    if(setsInput.value.trim() == ''){
      setsInput.classList.add('is-invalid')
    }

    if(setsInput.classList.contains('is-invalid') || repsInput.classList.contains('is-invalid')){
      return
    }

    const id = document.querySelector('#edit-exercise-modal #edit-exercise-id').value
    const week = document.querySelector('#edit-exercise-modal #edit-exercise-week').value
    const day = document.querySelector('#edit-exercise-modal #edit-exercise-day').value

    document.querySelector('input[name="physio['+week+']['+day+']['+id+'][sets]"]').value = setsInput.value
    document.querySelector('input[name="physio['+week+']['+day+']['+id+'][reps]"]').value = repsInput.value

    document.getElementById(week+'-'+day+'-'+id+'-reps').innerHTML = repsInput.value
    document.getElementById(week+'-'+day+'-'+id+'-sets').innerHTML = setsInput.value

    this.hideEditModal()
  }

  getCurrentWeek(){
    return document.querySelector('#small-selector .physio-week-selector.active')?.getAttribute('data-week')
  }

  handleSearchKeyup(e){
    const week = this.getCurrentWeek();

    this.searchExercise(e.target.value, week)
  }

  searchExercise(keyword, week){
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      const assignedIds = this.getAlreadyAssignedExercisesForWeek(week)
      const csrf = document.querySelector('meta[name="csrf-token"]')
      fetch('/physio/search_exercise', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrf.content,
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          body_parts: this.selectedBodyParts,
          keyword: keyword,
          without_ids: assignedIds
        })
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
    }, 500)
  }

  getAlreadyAssignedExercisesForWeek(week){
    const inputs = Array.from(document.querySelectorAll('#physio-week-'+week+' input.physio-input'))
    const ids = [];

    inputs.forEach(input => {
      let parts = input.name.split('[');
      const id = parts[3].split(']')[0];

      if(ids.indexOf(id) == -1) ids.push(id);
    })
    
    return ids;
  }

  detectClickOutside(){
    document.addEventListener('click', e => {
      const element = document.getElementById('search-physio')
      const dropdown = document.querySelector('#search-exercise-dropdown')
      const searchInput = document.getElementById('search-exercise-input')

      if (!element.contains(e.target)){
        dropdown.style.display = 'none'
        searchInput.value = ''
      }
    });
  };

  showAddModal(e){
    const id = e.currentTarget.getAttribute('data-id')
    const week = this.getCurrentWeek();
    const inputs = document.querySelectorAll('#physio-week-'+week+' input.form-check-input[id*="day"]');

    document.querySelector('input#add-exercise-id').value = id
    
    let html = ''

    inputs.forEach(input => {
      if(input.checked){
        html += '<div class="form-check form-check-inline">'
          html += '<input class="form-check-input float-unset" type="checkbox" id="add-day-'+input.value+'" value="'+input.value+'" '+(input.checked ? 'checked' : '')+'>'
          html += '<label class="form-check-label ms-1 text-gray2" for="add-day-'+input.value+'">'+input.value+'</label>'              
        html += '</div>'
      }
    })

    document.getElementById('add-exercise-for-days').innerHTML = html
    this.addModal.show()
  }

  hideAddModal(e){
    e?.stopPropagation()
    this.addModal.hide()
  }

  enableDisableSearch(){
    const mask = document.getElementById('exercise-search-mask')
    const week = this.getCurrentWeek();
    const selectedDays = Array.from(document.querySelectorAll('#physio-week-'+week+' input.form-check-input[id*="day"]')).filter(input => input.checked);
    
    if(this.selectedBodyParts.length == 0 || selectedDays.length == 0){
      mask.classList.remove('d-none')
    }else{
      mask.classList.add('d-none')
    }
  }

  async addExercise(){
    const exerciseId = document.querySelector('input#add-exercise-id').value
    const dayInputs = document.querySelectorAll('input.form-check-input[id*=add-day-]')
    const days = []

    dayInputs.forEach(input => {
      if(input.checked) days.push(input.value);
    })

    const errorMessage = document.getElementById('add-exercise-error-message')
    errorMessage.classList.add('d-none')

    if(days.length == 0){
      errorMessage.classList.remove('d-none')
      return
    }

    this.hideAddModal();
    this.showLoader();

    const physioInputs = this.getPhysioInputs();
    const currentWeek = this.getCurrentWeek()
    const csrf = document.querySelector('meta[name="csrf-token"]')

    await fetch('/physio/add_exercise', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        physio: physioInputs,
        week: currentWeek,
        id: exerciseId,
        days: days
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(err => console.log(err))
    this.hideLoader()
  }
}
