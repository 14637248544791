<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.re_admitted_question_html"></div>
        <div class="d-flex justify-content-between mb-5">
            <button class="btn btn-white rio-pdq-button w-165px me-1" :class="{'active' : store.formData.re_admitted_to_hospital === true}" @click="select(true)">{{ langStore.lang.yes_answer }}</button>
            <button class="btn btn-white rio-pdq-button w-165px ms-1" :class="{'active' : store.formData.re_admitted_to_hospital === false}" @click="select(false)">{{ langStore.lang.no_answer }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';

const store = usePdqStore();
const langStore = useLangStore();

const select = (value) => {
    store.formData.re_admitted_to_hospital = value

    if(value){
        store.nextStep()
    }else{
        store.skipSteps(2);
    }
}
</script>