import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multiple-select"
export default class extends Controller {
  connect() {
    this.selectorTextElement = this.element.querySelector('.selected-text')
    this.selectPleaseLabel = this.element.getAttribute('data-select-please-lang')
    this.itemsSelectedLabel = this.element.getAttribute('data-items-selected-lang')
    this.setSelectorText()
  }

  setSelectorText(){
    let checkedInputs = 0;
    const inputs = this.element.querySelectorAll('input')

    inputs.forEach(input => {
      if(input.checked) checkedInputs++;
    })

    if(checkedInputs > 0){
      this.selectorTextElement.innerHTML = checkedInputs+' '+this.itemsSelectedLabel
    }else{
      this.selectorTextElement.innerHTML = this.selectPleaseLabel
    }
  }
}
