<template>
    <div class="pdq-progress-bar-container">
        <div class="pdq-progress-bar" :style="{width: width+'%'}"></div>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps(['step'])

const width = computed(() => Number(props.step) / 9 * 100)
</script>