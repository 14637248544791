import { defineStore } from "pinia/dist/pinia.cjs";
import { computed, ref, watch } from "vue";
import ApiService from "../services/api_service";
import { Modal } from "bootstrap";

export const usePatientStore = defineStore('patient', () => {
    const modal = ref(null)
    const step = ref('personal-details')
    const nhsEnabled = ref(true)
    const nhsValidationEnabled = ref(true)
    const physioEnabled = ref(true)
    const errors = ref({})
    const diagnosisOptions = ref([])
    const procedureOptions = ref([])
    const specialtyOptions = ref({})
    const clinicalTeams = ref([])
    const exerciseDays = ref([])

    const formData = ref({})
    const resetFormData = () => {
        formData.value = {
            nhsnumber: null,
            firstname: null,
            lastname: null,
            dateofbirth: null,
            gender: null,
            hospitalnumber: null,
            gsmcountrycode: null,
            gsm: null,
            email: null,
            height: null,
            weight: null,
            diagnosistypesnomed: null,
            diagnosistypesnomedtext: null,
            require_procedure: '1',
            operationtypesnomedtext: null,
            operationtypesnomed: null,
            operationdate: null,
            bodyside: null,
            specialty: null,
            operationtype: null,
            clinicalteam_id: null,
            activities: null,
            physio: null,
            caretakerfirstname: null,
            caretakerlastname: null,
            caretakergsm: null,
            caretakergsmcountrycode: null,
            caretakeremail: null
        }
    }

    resetFormData();

    const create = () => {
        return new Promise((resolve, reject) => {
            ApiService.post('/patients/create_new_patient', { ...formData.value, step: step.value }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                errors.value = response.data
                reject(response)
            })
        })
    }

    const searchDiagnosis = (keyword, loading) => {
        return new Promise((resolve, reject) => {
            ApiService.get('/snomed/search_diagnosis.json?keyword=' + keyword).then(({ data }) => {
                if (data.length > 0) {
                    diagnosisOptions.value = data
                }
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const searchProcedure = (keyword, loading) => {
        return new Promise((resolve, reject) => {
            ApiService.get('/snomed/search_procedure.json?keyword=' + keyword).then(({ data }) => {
                if (data.length > 0) {
                    procedureOptions.value = data
                }
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const getCarePlanTemplate = (clinicalTeamId, operationType) => {
        return new Promise((resolve, reject) => {
            ApiService.get('/care_plan/template.json?clinicalteam_id=' + clinicalTeamId + '&operationtype=' + operationType).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const getLinkformActivities = patientId => {
        return new Promise((resolve, reject) => {
            ApiService.post('/patients/' + patientId + '/linkform_activities.json', {}).then(({ data }) => {
                formData.value.activities = data
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const getCarePlanActivities = patientId => {
        return new Promise((resolve, reject) => {
            ApiService.post('/patients/' + patientId + '/careplan_activities.json', {}).then(({ data }) => {
                formData.value.activities = data
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const updateCarePlan = (patientId, data) => {
        return new Promise((resolve, reject) => {
            ApiService.put('/patients/' + patientId + '/update_care_plan.json', data).then(({ data }) => {
                formData.value.activities = data
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const updatePhysio = (patientId) => {
        return new Promise((resolve, reject) => {
            ApiService.put('/patients/' + patientId + '/update_physio_plan.json', {
                physio: formData.value.physio
            }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const setExerciseDays = () => {
        if(formData.value.activities == null) return;
        
        const carePlanColumn = getCarePlanColumn();

        const assignedDays = Object.fromEntries(
            Object.entries(formData.value.activities.schedule[carePlanColumn] ?? {}).filter(([key, value]) => value.assigned)
        );

        exerciseDays.value = Object.keys(assignedDays).map(item => Number(item))
    }

    watch(() => formData.value.activities, () => {
        setExerciseDays()
    }, {deep: true})

    const initModal = (element) => {
        modal.value = new Modal(element)
        element.addEventListener('hidden.bs.modal', () => {
            resetFormData();
            step.value = 'personal-details'
            errors.value = {}
        })
    }

    const showPhysio = computed(() => {
        return physioEnabled.value && ['to', 'other'].includes(formData.value.specialty)
    })

    const filteredOperationTypes = computed(() => {
        return specialtyOptions.value[formData.value.specialty]
    })

    const getCarePlanColumn = () => {
        let carePlanColumn;
        switch (formData.value.operationtype) {
            case 'ot_hip':
                carePlanColumn = 'physio_hip';
                break;
            case 'ot_knee':
                carePlanColumn = 'physio_knee';
                break;
            case 'ot_spine':
                carePlanColumn = 'physio_spine';
                break;
            case 'ot_fa':
                carePlanColumn = 'physio_fa';
                break;
            default:
                carePlanColumn = 'physio';
        }

        return carePlanColumn
    }

    const nextStep = () => {
        switch (step.value) {
            case 'personal-details':
                step.value = 'clinical-details';
                break;
            case 'clinical-details':
                step.value = 'care-plan';
                break;
            case 'care-plan':
                step.value = showPhysio.value ? 'physio' : 'caregiver-details';
                break;
            case 'physio':
                step.value = 'caregiver-details';
                break;
        }
    }

    const prevStep = () => {
        switch (step.value) {
            case 'clinical-details':
                step.value = 'personal-details';
                break;
            case 'care-plan':
                step.value = 'clinical-details';
                break;
            case 'physio':
                step.value = 'care-plan';
                break;
            case 'caregiver-details':
                step.value = showPhysio.value ? 'physio' : 'care-plan';
        }
    }

    return {
        modal,
        step,
        initModal,
        showPhysio,
        errors,
        formData,
        nhsEnabled,
        create,
        searchDiagnosis,
        diagnosisOptions,
        searchProcedure,
        procedureOptions,
        specialtyOptions,
        filteredOperationTypes,
        clinicalTeams,
        nextStep,
        prevStep,
        getCarePlanTemplate,
        getLinkformActivities,
        getCarePlanActivities,
        exerciseDays,
        getCarePlanColumn,
        updatePhysio,
        updateCarePlan,
        nhsValidationEnabled
    }
})