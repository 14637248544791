<template>
    <div class="h-100 d-flex flex-column">
        <div class="mb-4 d-flex align-items-center justify-content-between">
            <back-button @click="store.prevStep"/>
            <close-button @click="createUpdateUrlParam('exit', true)"/>
        </div>
        <div class="fs-16px text-gray13 mb-3">{{ store.step }} {{ langStore.lang.progress_bar_text }}</div>
        <pdq-progress-bar class="mb-5" :step="store.step" />
        <PdqStep1 v-if="store.step == 1" />
        <PdqStep2 v-if="store.step == 2" />
        <PdqStep3 v-if="store.step == 3" />
        <PdqStep4 v-if="store.step == 4" />
        <PdqStep5 v-if="store.step == 5" />
        <PdqStep6 v-if="store.step == 6" />
        <PdqStep7 v-if="store.step == 7" />
        <PdqStep8 v-if="store.step == 8" />
        <PdqStep9 v-if="store.step == 9" />
    </div>
    
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
import { createUpdateUrlParam } from '../../utils/create_update_url_param';
import PdqProgressBar from './PdqProgressBar.vue';
import BackButton from '../BackButton.vue';
import CloseButton from '../CloseButton.vue';
import PdqStep1 from './PdqStep1.vue';
import PdqStep2 from './PdqStep2.vue';
import PdqStep3 from './PdqStep3.vue';
import PdqStep4 from './PdqStep4.vue';
import PdqStep5 from './PdqStep5.vue';
import PdqStep6 from './PdqStep6.vue';
import PdqStep7 from './PdqStep7.vue';
import PdqStep8 from './PdqStep8.vue';
import PdqStep9 from './PdqStep9.vue';

const store = usePdqStore();
const langStore = useLangStore();
const props = defineProps(['lang', 'leakTypes', 'sympthoms', 'healthWorkerTypes', 'pdq'])

store.formData = JSON.parse(props.pdq)
store.leakTypes = JSON.parse(props.leakTypes)
store.sympthoms = JSON.parse(props.sympthoms)
store.healthWorkerTypes = JSON.parse(props.healthWorkerTypes)

langStore.lang = JSON.parse(props.lang)

</script>