import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ward-visit"
export default class extends Controller {
  connect() {
  }

  addRow(){
    const rows = Array.from(this.element.querySelectorAll('div[data-index]'))
    const indexes = []
    rows.forEach(row => indexes.push(Number(row.getAttribute('data-index'))))
    const maxIndex = Math.max(...indexes)
    const nextIndex = maxIndex + 1;
    const lastRow = this.element.querySelector('div[data-index="'+maxIndex+'"]')
    const calendarIcon = lastRow.querySelector('.calendar-icon')
    const dateLabel = lastRow.querySelector('.visit-date-label').innerHTML
    const sympthomsLabel = lastRow.querySelector('.ward-sympthoms-label').innerHTML
    const otherCriteriaLabel = lastRow.querySelector('.ward-other-criteria-label').innerHTML
    const actionLabel = lastRow.querySelector('.ward-action-label').innerHTML
    const deleteRowLabel = this.element.getAttribute('data-delete-lang')
    
    let html = '<div class="d-flex justify-content-end mb-2"><a href="javascript:;" class="text-decoration-none text-danger" data-action="ward-visit#deleteRow" data-index="'+nextIndex+'">'+deleteRowLabel+'</a></div>'

      html += '<div class="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">'
        html += '<div data-controller="flatpickr">'
          html += '<div class="input-group input-border-radius input-box-shadow">'
            html += '<input type="text" name="ward_visits['+nextIndex+'][visit_date]" id="visit_date_'+nextIndex+'" value="" class="form-control form-control-lg text-dark-blue light-bg enable-left-radius no-border-right fp_date" placeholder="01/01/2000">'
            html += '<span class="input-group-text light-bg">'
              html += calendarIcon.outerHTML
            html += '</span>'
          html += '</div>'
        html += '</div>'
        html += '<div class="fs-13px text-gray11 visit-date-label">'+dateLabel+'</div>'
      html += '</div>'

      html += '<div class="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">'
        html += '<textarea name="ward_visits['+nextIndex+'][sympthoms]" class="form-control form-control-lg text-dark-blue"></textarea>'
        html += '<div class="fs-13px text-gray11 ward-sympthoms-label">'+sympthomsLabel+'</div>'
      html += '</div>'

      html += '<div class="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">'
        html += '<textarea name="ward_visits['+nextIndex+'][other_criteria]" class="form-control form-control-lg text-dark-blue"></textarea>'
        html += '<div class="fs-13px text-gray11 ward-other-criteria-label">'+otherCriteriaLabel+'</div>'
      html += '</div>'

      html += '<div class="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">'
        html += '<textarea name="ward_visits['+nextIndex+'][action]" class="form-control form-control-lg text-dark-blue"></textarea>'
        html += '<div class="fs-13px text-gray11 ward-action-label">'+actionLabel+'</div>'
      html += '</div>'

    const newRow = document.createElement('div')
    newRow.classList.add('row', 'mb-3')
    newRow.setAttribute('data-index', nextIndex)
    newRow.innerHTML = html
    lastRow.after(newRow)
  }

  deleteRow(e){
    const index = e.currentTarget.getAttribute('data-index')
    const row = this.element.querySelector('div[data-index="'+index+'"]')
    row.remove()
  }
}
