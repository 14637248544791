import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// Connects to data-controller="hz-chart"
export default class extends Controller {

  static targets = ['chart'];

  connect() {
    for(let i = 0; i < this.chartTargets.length; i++){
      this.generateChart(this.chartTargets[i]);
    }
    Turbo.cache.exemptPageFromPreview()
  }

  generateChart(element){
    const columns = JSON.parse(element.getAttribute('data-columns'));
    const categoryField = element.getAttribute('data-category-field');
    const data = JSON.parse(element.getAttribute('data-data'));
    am5.ready(() => {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new(element);
    
      root._logo.dispose();
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
    
    
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        paddingLeft: 0,
        arrangeTooltips: false,
        pinchZoomX: true
      }));
    
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var yRenderer = am5xy.AxisRendererY.new(root, {
        minorGridEnabled: true,
        minGridDistance:20,

      })

      yRenderer.grid.template.set("visible", false);

      yRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      yRenderer.labels.template.set("fontSize", 12);
      yRenderer.labels.template.set("paddingRight", 12);
    
      var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: categoryField,
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
      
      yAxis.data.setAll(data);
    
      var xRenderer = am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1,
        forceHidden: true,
        minorGridEnabled: true,
        minGridDistance: 50

      })
    
      xRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      xRenderer.labels.template.set("fontSize", 12);
      xRenderer.labels.template.set("paddingTop", 12);
    
      var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: xRenderer
      }));
    
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name, fieldName, color) {
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: fieldName,
          categoryYField: categoryField,
        }));
    
        series.columns.template.setAll({
          height: 16,
          tooltipX: 0,
          strokeOpacity: 0,
          maxHeight: 16,
          cornerRadiusBL: 4,
          cornerRadiusBR: 4,
          cornerRadiusTL: 4,
          cornerRadiusTR: 4,
          fill: am5.color(color),
        });
    
        series.data.setAll(data);
    
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
    
    
      }
    
      const colors = {
        purple: 0x9E84FF,
        blue: 0x81BCFF
      }
    
      for (let i = 0; i < columns.length; i++) {
        makeSeries(columns[i].title, columns[i].key, colors[columns[i].color]);
      }
    
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      
    });    
  }
}
