<template>
    <div class="flex-fill d-flex flex-column">
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.note_title"></div>

        <textarea class="form-control input-border-radius mb-3" v-model="store.formData.note" rows="5" :placeholder="langStore.lang.note_placeholder"></textarea>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.next }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
import { createUpdateUrlParam } from '../../utils/create_update_url_param';

const url = window.location.href
const segments = url.split('/')
const id = segments[4]

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const store = usePdqStore();
const langStore = useLangStore();

const submit = () => {
    store.submit(id, token).then(() => {
        createUpdateUrlParam('completed', true)
    })
}
</script>