import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// Connects to data-controller="proms"
export default class extends Controller {

  static targets = ['chart'];

  connect() {
    for(let i = 0; i < this.chartTargets.length; i++){
      this.generateChart(this.chartTargets[i]);
    }
    Turbo.cache.exemptPageFromPreview()
  }

  generateChart(element){
    const columns = JSON.parse(element.getAttribute('data-columns'));
    const categoryField = element.getAttribute('data-category-field');
    const data = JSON.parse(element.getAttribute('data-data'));

    am5.ready(() => {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new(element);
      
      root._logo.dispose();
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
      
      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));  
      
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: categoryField == "week" ? 0.33 : 0.2,
        cellEndLocation: categoryField == "week" ? 0.67 : 0.8,
        minorGridEnabled: true
      })

      xRenderer.grid.template.set("visible", false);
      xRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      xRenderer.labels.template.set("fontSize", 14);
      
      var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: categoryField,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
      
      xAxis.data.setAll(data);

      var yRenderer = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
        forceHidden: true
      })

      yRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      yRenderer.labels.template.set("fontSize", 14);
      
      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yRenderer
      }));

      // yRenderer.labels.template.set("fill", series.get("fill"));
      
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name, fieldName, color) {
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField:categoryField,
        }));
      
        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}:{valueY}",
          width: 16,
          tooltipY: 0,
          strokeOpacity: 0,
          maxWidth: 16,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          fill: am5.color(color),
        });
      
        series.data.setAll(data);
      
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
      
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          });
        });
      
      }

      const colors = {
        purple: 0x9E84FF,
        blue: 0x81BCFF
      }
      
      for(let i=0;i<columns.length;i++){
        makeSeries(columns[i].title, columns[i].key, colors[columns[i].color]);
      }
      
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      
      }); // end am5.ready()
  }
}
