import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="media"
export default class extends Controller {

  static targets = ['day', 'week', 'dateTime']

  connect() {
  }

  async setVideoSource(e){
    e.preventDefault();
    this.setMetaData(e.currentTarget)
    const video = document.getElementById('video-tag')
    const sourceTag = document.getElementById('video-source-tag');

    await Promise.resolve(sourceTag.src = e.currentTarget.href)
    await Promise.resolve(video.load())

    const modalEl = document.getElementById('video-modal')
    const modal = new Modal(modalEl)
    modal.show()

    modalEl.addEventListener('hidden.bs.modal',() => {
      video.pause()
    })
  } 

  async setImageSource(e){
    e.preventDefault();
    this.setMetaData(e.currentTarget)
    const imageTag = document.getElementById('image-tag');
    await Promise.resolve(imageTag.src = e.currentTarget.href)

    const modalEl = document.getElementById('image-modal')
    const modal = new Modal(modalEl)
    modal.show()
  }

  setMetaData(element){
    const day = element.getAttribute('data-day')
    const week = element.getAttribute('data-week')
    const dateTime = element.getAttribute('data-date-time')

    this.dayTargets.map(item => item.innerHTML = day)
    this.weekTargets.map(item => item.innerHTML = week)
    this.dateTimeTargets.map(item => item.innerHTML = dateTime)
  }
}
