<template>
    <div class="modal fade" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-md-down h-100 m-0-auto">
            <div class="modal-content">
                <div class="border-bottom">
                    <div class="row mx-0">
                        <div class="col-12 col-lg-4 px-0">
                            <div class="px-4 py-3">
                                <h5 class="modal-title text-dark-blue text-start">{{ langStore.lang.views.invite_patient
                                    }}</h5>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">

                        </div>
                    </div>
                    <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-modal-custom">
                        <img :src="x">
                    </a>
                </div>

                <div class="scrollable-modal-body">
                    <div class="row mx-0">
                        <div class="col-12 col-lg-4 px-0">
                            <div class="border-lg-end py-4 ps-3 pe-4 h-100">
                                <PatientProgressBar />
                            </div>
                        </div>
                        <div class="col-12 col-lg-8 px-0">

                            <div class="p-3 pb-0" v-if="patientStore.errors.generic">
                                <ErrorMessage :message="patientStore.errors.generic[0]" :shadow="true" />
                            </div>

                            <PersonalDetails ref="personalDetails" v-show="patientStore.step == 'personal-details'" />
                            <ClinicalDetails v-show="patientStore.step == 'clinical-details'" />
                            <CarePlan 
                                :activities="patientStore.formData.activities" 
                                :week="1"
                                :clinical-team-id="patientStore.formData.clinicalteam_id"
                                :operation-type="patientStore.formData.operationtype" 
                                :edit-mode="true"
                                :lang="langStore.lang" v-if="patientStore.step == 'care-plan'">
                                <template v-slot:header>
                                    <div class="flex-fill overflow-hidden">
                                        <div class="text-primary text-start fs-18px text-ellipsis"><strong>{{ langStore.lang.views.review_care_plan }}</strong> (<span>{{ patientStore.formData.diagnosistypesnomedtext }}</span>)</div>
                                    </div>
                                </template>
                            </CarePlan>
                            <Physio v-if="patientStore.step == 'physio'" 
                                :lang="langStore.lang"
                                :operation-type="patientStore.formData.operationtype"
                                :physio="patientStore.formData.physio" 
                                :week="1" 
                            />
                            <CareGiverDetails ref="caregiverDetails" v-show="patientStore.step == 'caregiver-details'"/>

                        </div>
                    </div>
                </div>

                <div class="border-top">
                    <div class="row mx-0">
                        <div class="col-12 col-lg-4 px-0">
                            <div class="border-lg-end h-100">

                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <div class="p-3 d-flex justify-content-between">
                                <button type="submit" name="step_back" class="btn btn-orange-outline px-4"
                                    v-if="patientStore.step != 'personal-details'" @click="patientStore.prevStep">
                                    {{ langStore.lang.views.step_back }}
                                </button>
                                <button type="submit" class="btn btn-orange px-4 ms-auto" ref="nextButton" @click="nextStep">
                                    {{ nextButtonText }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { usePatientStore } from '../../stores/patient';
import { useLangStore } from '../../stores/lang';
import x from '@images/close-modal.svg'
import PatientProgressBar from './PatientProgressBar.vue';
import PersonalDetails from './PersonalDetails.vue';
import ClinicalDetails from './ClinicalDetails.vue';
import CarePlan from './CarePlan.vue';
import Physio from './Physio.vue';
import CareGiverDetails from './CareGiverDetails.vue';
import { setLoading, disableLoading } from '../../utils/button_helper'
import ErrorMessage from '../ErrorMessage.vue';

const patientStore = usePatientStore();
const modal = ref(null)
const langStore = useLangStore();
const personalDetails = ref(null)
const caregiverDetails = ref(null)

onMounted(() => {
    patientStore.initModal(modal.value)
})

const nextButtonText = computed(() => {
    let text = '';
    switch (patientStore.step) {
        case 'personal-details':
            text = langStore.lang.views.insert_clinical_details;
            break;
        case 'clinical-details':
            text = langStore.lang.views.review_care_plan;
            break;
        case 'care-plan':
            text = patientStore.showPhysio ? langStore.lang.views.review_physiotherapy : langStore.lang.views.next_caregiver_details;
            break;
        case 'physio':
            text = langStore.lang.views.next_caregiver_details;
            break;
        case 'caregiver-details':
            text = langStore.lang.views.send_invitation;
            break;
    }
    return text;
})

const nextButton = ref(null)

const nextStep = async () => {
    patientStore.errors = {}

    setLoading(nextButton.value)

    if (patientStore.step == 'personal-details') {
        const iti = window.intlTelInputGlobals.getInstance(personalDetails.value.phoneInput);
        patientStore.formData.gsmcountrycode = iti.selectedCountryData.dialCode
    }

    if (patientStore.step == 'caregiver-details') {
        const iti = window.intlTelInputGlobals.getInstance(caregiverDetails.value.phoneInput);
        patientStore.formData.caretakergsmcountrycode = iti.selectedCountryData.dialCode
    }

    await patientStore.create().then(r => {
        if(r.id){
            document.location.href = '/patients/'+r.id+'?show_created_msg=true'
        }else patientStore.nextStep();
    }).catch(() => {})

    disableLoading(nextButton.value)
}
</script>