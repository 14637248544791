import { Controller } from "@hotwired/stimulus";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// Connects to data-controller="line-chart"
export default class extends Controller {
  static targets = ["chart"];

  connect() {
    for (let i = 0; i < this.chartTargets.length; i++) {
      this.generateChart(this.chartTargets[i]);
    }
    Turbo.cache.exemptPageFromPreview();
  }

  generateChart(element) {
    const valueField = (element.getAttribute("data-value-field"));
    const categoryField = element.getAttribute("data-category-field");
    const data = JSON.parse(element.getAttribute("data-data"));
    const xminGridDistance = element.getAttribute("data-xmin-grid-distance");
    am5.ready(() => {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new(element);

      root._logo.dispose();
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelX: "zoomX",
          wheelY: "zoomX",
          wheelStep: 1.5
        })
      );

      var easing = am5.ease.linear;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let yRenderer=am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
        forceHidden: true,
        minGridDistance: 50,
        
      })
      yRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      yRenderer.labels.template.set("fontSize", 14);
      yRenderer.labels.template.set("paddingRight", 10);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer
        })
      );
      let xRenderer=am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: xminGridDistance || 20,
        
      });
      xRenderer.grid.template.set("visible", false);
      xRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      xRenderer.labels.template.set("fontSize", 14);
      xRenderer.labels.template.set("paddingTop", 10);
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: categoryField,

          renderer: xRenderer,
        })
      );
      xAxis.data.setAll(data[0].data);
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      data.forEach(function (dataSet) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            minBulletDistance: 10,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: valueField,
            categoryXField: categoryField,
            stroke: am5.color(dataSet.color),
            strokeWidth: 4,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{categoryX}: {valueY}"

            })
          })
        );
        
        series.strokes.template.setAll({
          strokeWidth: 5,
          templateField: "strokeSettings",
        });
        series.bullets.push(function (root) {
          return am5.Bullet.new(root, {
            
            sprite: am5.Circle.new(root, {
              radius: 5,
              stroke: series.get("stroke"),
              fill: "white",
              strokeWidth: 3,
            }),
          });
        });
        series.data.setAll(dataSet.data);
        
      });

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      chart.appear(1000, 100);
    });
  }
}
