<template>
    <div ref="dropdownContainer" class="position-relative form-select text-dark-blue">
        <v-select 
        v-model="value" 
        :options="props.options" 
        append-to-body 
        :calculate-position="withPopper" 
        @option:selected="selected" 
        :label="props.label" 
        :reduce="option => option[props.value]"
        @search="props.search"
        :get-option-label="props.optionLabel"
        :placeholder="langStore.lang.views.search_here"
        :input-id="props.id"
        />
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { createPopper } from '@popperjs/core'
import { useLangStore } from '../stores/lang';

const langStore = useLangStore();

const props = defineProps(['options', 'modelValue', 'label', 'value', 'search', 'optionLabel', 'id'])
const emit = defineEmits(['update:modelValue', 'selected'])

const value = ref(props.modelValue)

const selected = (e) => {
    emit('update:modelValue', value.value)
    emit('selected', e)
}

const placement = ref('bottom')
const dropdownContainer = ref(null)

const withPopper = (dropdownList, component, { width }) => {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      width = (Number(width.slice(0, -2)) + 28)+'px'

      dropdownList.style.width = width
      dropdownList.classList.add(props.id)

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(dropdownContainer.value, dropdownList, {
        placement: placement.value,
        modifiers: [
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-down',
                state.placement === 'bottom'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }
</script>