import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prehabilitation"
export default class extends Controller {
  connect() {
    this.setBodyPart('upper')
    this.setHoverColor('.upper-body');
    this.setHoverColor('.lower-body');

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  setHoverColor(className){
    const els = document.querySelectorAll(className)

    els.forEach(el => {
      el.addEventListener('mouseover', e => {
        els.forEach(element => {
          if(element.classList.contains('stroke')){
            element.style.stroke = '#E3DAFF'
          }else element.style.fill = '#E3DAFF';

          element.style.opacity = 0.5
        })
      })

      el.addEventListener('mouseleave', e => {
        els.forEach(element => {
          if(element.classList.contains('stroke')){
            element.style.stroke = '#979FB0'
          }else element.style.fill = 'transparent';

          element.style.opacity = 1
        })
      })
    })
  }

  handleBodyPartClick(e){
    const type = e.target.classList.contains('lower-body') ? 'lower' : 'upper'
    this.setBodyPart(type)
  }

  setBodyPart(type){
    this.bodyPart = type

    const upperBodyForm = document.getElementById('upper-body-form')
    const lowerBodyForm = document.getElementById('lower-body-form')

    const lowerBodyElements = document.querySelectorAll('.lower-body')
    const upperBodyElements = document.querySelectorAll('.upper-body')

    if(type == 'upper'){
      upperBodyForm.classList.remove('d-none')
      lowerBodyForm.classList.add('d-none')

      lowerBodyElements.forEach(el => el.classList.remove('active'))
      upperBodyElements.forEach(el => el.classList.add('active'))
    }else{
      upperBodyForm.classList.add('d-none')
      lowerBodyForm.classList.remove('d-none')

      lowerBodyElements.forEach(el => el.classList.add('active'))
      upperBodyElements.forEach(el => el.classList.remove('active'))
    }
  }
}
