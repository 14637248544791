import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
// Connects to data-controller="view-physio-video"
export default class extends Controller {
  connect() {
    this.modalEl = document.querySelector('#view-physio-video-modal')
    this.modal = new Modal(this.modalEl)
    this.iframe = this.modalEl.querySelector('#video-exercise-iframe')
  }

  showModal(e){
    const url = e.target.getAttribute('data-video') 
    this.iframe.src = url
    this.modal.show();
  }

  hideModal(){
    this.modal.hide();
    this.iframe.src = ''
  }
}
