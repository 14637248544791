import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification"
export default class extends Controller {

  static targets = ['countBadge']

  static values = {
    unreadNotificationsPresent: false
  }

  connect() {
    const notificationsDropdown = document.getElementById('clinician_notifications')
    notificationsDropdown.addEventListener('show.bs.dropdown', event => {
      if(this.unreadNotificationsPresentValue){
        this.markAsRead()
      }
    })
  }

  markAsRead(ids){
    const csrf = document.querySelector('meta[name="csrf-token"]')
    fetch('/notification/mark_as_read', {
        method : "put",
        headers: {
          'X-CSRF-Token': csrf.content,
          'Content-Type': 'application/json'
        }
    })
    .then(() => {
      this.countBadgeTarget?.remove()
      this.unreadNotificationsPresentValue = false
    })
  }
}
