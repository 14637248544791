import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-patients"
export default class extends Controller {

  static targets = ["placeholder", "count", "knee", "hip", "fa", "spine", "ear", "nose", "throat", "gs", "colorectal"/*, "ent"*/]

  static values = {
    selectedItems: {
      type: Number,
      default: 0
    }
  }

  connect() {
    this.countSelectedItems()
  }

  countSelectedItems(){
    this.selectedItemsValue = 0
    this.element.querySelectorAll("input").forEach(item => {
      if(item.name == "operationtype[]" && item.checked){
        this.selectedItemsValue++
      }
    })

    if(this.selectedItemsValue > 0){
      this.countTarget.querySelector("span[class=num]").innerHTML = this.selectedItemsValue
      this.countTarget.classList.remove("d-none")
      this.placeholderTarget.classList.add("d-none")
    }
  }

  clearSelection(){
    this.element.querySelectorAll("input").forEach(item => {
      if(item.name == "operationtype[]"){
        item.checked = false
      }
    })
  }

  toChanged(e){

    if(e.target.checked){
      this.kneeTarget.checked = true;
      this.hipTarget.checked = true;
      this.faTarget.checked = true;
      this.spineTarget.checked = true;
    }else{
      this.kneeTarget.checked = false;
      this.hipTarget.checked = false;
      this.faTarget.checked = false;
      this.spineTarget.checked = false;
    }
  }

  entChanged(e){
    if(e.target.checked){
      this.earTarget.checked = true;
      this.noseTarget.checked = true;
      this.throatTarget.checked = true;
      // this.entTarget.checked = true;
    }else{
      this.earTarget.checked = false;
      this.noseTarget.checked = false;
      this.throatTarget.checked = false;
      // this.entTarget.checked = false;
    }
  }

  gsChanged(e){
    if(e.target.checked){
      this.gsTarget.checked = true;
      this.colorectalTarget.checked = true;
    }else{
      this.gsTarget.checked = false;
      this.colorectalTarget.checked = false;
    }
  }

}
