import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selector"
export default class extends Controller {

  static targets = ['param', 'keyword', 'results', 'textInput', 'idInput', 'selectedText', 'dropdown']

  connect() {
    this.dropdownTarget.addEventListener('show.bs.dropdown', () => {
      setTimeout(() => {
        this.keywordTarget.focus()
      }, 100)
    })
  }

  search(){
    let queryString = '';

    for(let i=0; i<this.paramTargets.length;i++){
      queryString += this.paramTargets[i].getAttribute('data-name')+'='+this.paramTargets[i].getAttribute('data-value')+'&'
    }

    queryString += 'keyword='+this.keywordTarget.value
    
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      fetch('/clinicians/autosuggest?'+queryString, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
    }, 500)
  }

  selectItem(e){
    const item = JSON.parse(e.target.getAttribute('data-item'))
    
    this.textInputTarget.value = item.term
    this.idInputTarget.value = item.conceptId
    this.selectedTextTarget.innerHTML = item.term+' - '+item.conceptId
    this.keywordTarget.value = ""
  }
}
