import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { initIntlTelInput } from "../utils/init-intl-tel-input"

// Connects to data-controller="linkform"
export default class extends Controller {
  connect() {
    initIntlTelInput()
    this.checkOtherInput()
  }

  showModal(){
    const el = document.getElementById('linkformModal')
    const modal = new Modal(el)
    modal.show()
    el.addEventListener('hidden.bs.modal', () => {
      Turbo.visit(window.location.pathname, { action: 'replace' });
    })
  }

  sendToChanged(e){
    const el = document.getElementById('linkform-phone-input')
    if(e.target.value == 'other'){
      el.classList.remove('d-none')
    }else el.classList.add('d-none')
  }

  checkOtherInput(){
    const inputs = document.querySelectorAll('input[name=send_to]')
    inputs.forEach(input => {
      if(input.value == 'other' && input.checked){
        const el = document.getElementById('linkform-phone-input')
        el.classList.remove('d-none')
      }
    })
  }

  handleBeforeSubmit(e){

    const disabledInputs = e.target.querySelectorAll('input[disabled]')
    disabledInputs.forEach(element => element.disabled = false)
    const disabledTextarea = e.target.querySelectorAll('textarea[disabled]')[0]
    disabledTextarea.disabled = false

    //set gsm code for phone input
    const phoneInput = e.target.getElementsByClassName('phone-input')[0]
    const iti = window.intlTelInputGlobals.getInstance(phoneInput);
    const gsmCountryInput = e.target.querySelectorAll('input[name=gsmcountry]')[0]
    gsmCountryInput.value = iti.selectedCountryData.dialCode
  }

  copyToClipboard(){
    let text = document.getElementById('linkcode-input').value;
    navigator.clipboard.writeText(text);
  }
}
