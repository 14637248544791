<template>
    <div class="modal fade" id="edit-physio-plan-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true" data-patient-modal-target="modal">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-md-down h-100 m-0-auto">
            <div class="modal-content overflow-y-auto">
                <div class="border-bottom px-4 py-3">
                <h5 class="modal-title text-dark-blue">Modify patient’s physiotherapy plan</h5>
                <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-modal-custom">
                    <img :src="getImageURL('close-modal.svg')">
                </a>
                </div>
                <div>
                <Physio  
                    :lang='props.lang' 
                    :operation-type="props.operationType"
                    :physio='props.physio'
                    :week="props.week" 
                    :exercise-days='props.exerciseDays'
                    :body-parts='props.bodyParts'
                    :exercises='props.exercises'
                />  
                </div>
                <div class="py-3 px-4 text-end border-top">
                    <button class="btn btn-orange w-120" type="button" ref="saveButton" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { usePatientStore } from '../../stores/patient';
import { getImageURL } from '../../utils/image_url_helper';
import Physio from '../patient/Physio.vue';
import { setLoading, disableLoading } from '../../utils/button_helper';

const props = defineProps(['lang', 'operation-type', 'physio', 'week', 'exercise-days', 'body-parts', 'exercises', 'patientId'])

const patientStore = usePatientStore();

const saveButton = ref(null)

const save = async () => {
    setLoading(saveButton.value)
    await patientStore.updatePhysio(props.patientId).then(() => {
        Turbo.visit(document.location.href)
    })
}
</script>