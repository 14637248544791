<template>
    <div class="p-4 text-start">
        <div class="mb-3 position-relative">
            <label for="diagnosistypesnomedtext" class="form-label modal-label required">{{ langStore.lang.views.diagnosis_label }}</label>
            <Selector 
                id="diagnosis-selector"
                :class="{'is-invalid' : patientStore.errors.diagnosistypesnomedtext}"
                v-model="patientStore.formData.diagnosistypesnomed" 
                :options="patientStore.diagnosisOptions" 
                @selected="diagnosisSelected" 
                label="term" 
                value="conceptId"
                :search="patientStore.searchDiagnosis"
                :option-label="option => option['term']+' - '+option['conceptId']"
            />
            <span class="text-danger fs-12px" v-if="patientStore.errors.diagnosistypesnomedtext">{{ patientStore.errors.diagnosistypesnomedtext[0] }}</span>
        </div>

        <div class="mb-3">
            <label class="form-label modal-label">{{ langStore.lang.views.procedure_label }}</label>
            <div class="form-check form-switch">
                <input class="form-check-input float-unset" type="checkbox" role="switch" true-value="1" false-value="0" v-model="patientStore.formData.require_procedure" :checked="patientStore.formData.require_procedure == '1'">
            </div>
        </div>

        <div class="row mb-3 position-relative">
            
            <div class="gray-layer" v-if="patientStore.formData.require_procedure == '0'"></div>

            <div class="col-12 col-xl-6 mb-3 mb-xl-0">
                <label for="operationtypesnomedtext" class="form-label modal-label required">{{ langStore.lang.views.procedure_type_label }}</label>
                <Selector 
                id="procedure-selector"
                :class="{'is-invalid' : patientStore.errors.operationtypesnomedtext}"
                v-model="patientStore.formData.operationtypesnomed" 
                :options="patientStore.procedureOptions" 
                @selected="procedureSelected" 
                label="term" 
                value="conceptId"
                :search="patientStore.searchProcedure"
                :option-label="option => option['term']+' - '+option['conceptId']"
                :key="patientStore.formData.operationtypesnomedtext"
            />
            <span class="text-danger fs-12px" v-if="patientStore.errors.operationtypesnomedtext">{{ patientStore.errors.operationtypesnomedtext[0] }}</span>
            </div>

            <div class="col-12 col-xl mb-3 mb-xl-0">
                <label for="operationdate" class="form-label modal-label required">{{ langStore.lang.views.procedure_date_label }}</label>
                <div data-controller="flatpickr">
                    <div class="input-group input-border-radius input-box-shadow" :class="{'is-invalid' : patientStore.errors.operationdate}">
                        <input type="text" id="operationdate" ref="operationDate" v-model="patientStore.formData.operationdate" class="form-control form-control-lg text-dark-blue light-bg enable-left-radius no-border-right fp_date" placeholder="01/01/2000">
                        <span class="input-group-text light-bg">
                            <img :src="calendarIcon">
                        </span>
                    </div>
                    <span class="text-danger fs-12px" v-if="patientStore.errors.operationdate">{{ patientStore.errors.operationdate[0] }}</span>
                </div>
            </div>
            <div class="col-12 col-xl">
                <label for="bodyside" class="form-label modal-label">{{ langStore.lang.views.body_side_label }}</label>
                <select class="form-select text-dark-blue" :class="{'is-invalid' : patientStore.errors.bodyside}" name="bodyside" id="bodyside" v-model="patientStore.formData.bodyside">
                    <option :value="null">{{ langStore.lang.views.select_please }}</option>
                    <option value="left">{{ langStore.lang.views.left }}</option>
                    <option value="right">{{ langStore.lang.views.right }}</option>
                </select>
                <span class="text-danger fs-12px" v-if="patientStore.errors.bodyside">{{ patientStore.errors.bodyside[0] }}</span>
            </div>
            
        </div>

        <div class="row mb-3">
            <div class="col-12 col-xl-6 mb-3 mb-xl-0">
                <label for="specialty" class="form-label modal-label required">{{ langStore.lang.views.speciality_label }}</label>
                <select class="form-select text-dark-blue" name="specialty" :class="{'is-invalid' : patientStore.errors.specialty}" v-model="patientStore.formData.specialty" @change="specialtySelected">
                    <option :value="null" disabled hidden>{{ langStore.lang.views.select_please }}</option>
                    <option :value="key" :key="key" v-for="key in Object.keys(patientStore.specialtyOptions)">{{ langStore.lang.specialties[key] }}</option>
                </select>
                <span class="text-danger fs-12px" v-if="patientStore.errors.specialty">{{ patientStore.errors.specialty[0] }}</span>
            </div>
            <div class="col-12 col-xl-6">
                <label for="operationtype" class="form-label modal-label required">{{ langStore.lang.views.care_plan_label }}</label>
                <select class="form-select text-dark-blue" name="operationtype" :class="{'is-invalid' : patientStore.errors.operationtype}" v-model="patientStore.formData.operationtype">
                    <option :value="null" selected disabled hidden>{{ langStore.lang.views.select_please }}</option>
                    <option :value="key" :key="key" v-for="key in patientStore.filteredOperationTypes">{{ langStore.lang.operation_types[key] }}</option>
                </select>
                <span class="text-danger fs-12px" v-if="patientStore.errors.operationtype">{{ patientStore.errors.operationtype[0] }}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-6">
                <label for="clinicalteam_id" class="form-label modal-label required">{{ langStore.lang.views.clinical_team }}</label>
                <select class="form-select text-dark-blue" name="clinicalteam_id" :class="{'is-invalid' : patientStore.errors.clinicalteam_id}" v-model="patientStore.formData.clinicalteam_id">
                    <option :value="null" disabled hidden>{{ langStore.lang.views.select_please }}</option>
                    <option :value="team.id" v-for="(team, key) in patientStore.clinicalTeams" :key="key">{{ team.name }}</option>
                </select>
                <span class="text-danger fs-12px" v-if="patientStore.errors.clinicalteam_id">{{ patientStore.errors.clinicalteam_id[0] }}</span>
            </div>
        </div>
        
    </div>

</template>

<script setup>
import { watch, ref } from 'vue';
import Selector from '../Selector.vue';
import { usePatientStore } from '../../stores/patient';
import { useLangStore } from '../../stores/lang';
import calendarIcon from '@images/calendar.svg'

const patientStore = usePatientStore();
const langStore = useLangStore();

const operationDate = ref(null)

watch(patientStore.formData, () => {
    if(!patientStore.formData.require_procedure){
        patientStore.formData.operationtypesnomedtext = null;
        patientStore.formData.operationtypesnomed = null;
        patientStore.formData.operationdate = null;
        operationDate.value._flatpickr.setDate(null)
        patientStore.formData.bodyside = null;   
    }
}, {deep: true})

const specialtySelected = () => {
    patientStore.formData.operationtype = patientStore.formData.specialty == 'other' ? 'ot_other' : null
}

watch(() => patientStore.formData.operationtype, () => {
    patientStore.formData.activities = null
    patientStore.formData.physio = null
})

const diagnosisSelected = e => {
    patientStore.formData.diagnosistypesnomedtext = e.term
    patientStore.formData.diagnosistypesnomed = e.conceptId
}

const procedureSelected = e => {
    patientStore.formData.operationtypesnomedtext = e.term
    patientStore.formData.operationtypesnomed = e.conceptId
}
</script>

