<template>
    <div class="modal fade" id="assign-exercise-modal" data-bs-backdrop="static" tabindex="-1"
        style="z-index:10000000000000">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down h-100 m-0-auto">
            <div class="modal-content input-border-radius border-unset">
                <div class="modal-header border-unset mb-2">
                    <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-modal-custom right-1rem">
                       <img :src="getImageURL('close-modal.svg')">
                    </a>
                </div>
                <div class="modal-body">
                    <div class="text-primary fs-20px fw-bold mb-3">Select the day to which you want to add the exercise</div>

                    <div id="add-exercise-for-days" class="d-flex justify-content-between mb-3">
                        <div class="form-check form-check-inline" v-for="day in Object.keys(patientStore.formData?.physio[currentWeek])">
                            <input class="form-check-input float-unset" type="checkbox" :id="'add-day-'+day" :value="day" v-model="selectedDays">
                            <label class="form-check-label ms-1 text-gray2" :for="'add-day-'+day">{{ day }}</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-unset">
                    <button type="button" class="btn btn-orange px-5"
                        :disabled="selectedDays.length == 0" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, getCurrentInstance, computed, watch } from 'vue';
import { usePhysioStore } from '../../stores/physio';
import { usePatientStore } from '../../stores/patient';
import { getImageURL } from '../../utils/image_url_helper';

const patientStore = usePatientStore();
const physioStore = usePhysioStore();

const instance = getCurrentInstance();

const currentWeek = computed(() => instance.parent.exposed.currentWeek.value)
const selectedDays = ref(JSON.parse(JSON.stringify(instance.parent.exposed.selectedDaysOfWeek.value)))

watch(() => instance.parent.exposed.selectedDaysOfWeek.value, () => {
    selectedDays.value = instance.parent.exposed.selectedDaysOfWeek.value
})

const save = () => {
    const carePlanColumn = patientStore.getCarePlanColumn();
    selectedDays.value.forEach(day => {
        const exists = patientStore.formData.physio[currentWeek.value][day][physioStore.currentExercise.id]
        if(!exists){
            patientStore.formData.physio[currentWeek.value][day][physioStore.currentExercise.id] = physioStore.currentExercise
            if(patientStore.formData.activities){
                patientStore.formData.activities.schedule[carePlanColumn][day].assigned = true
            }
        }
    })
    physioStore.addModal.hide()
}
</script>