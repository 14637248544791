<template>
    <div>
        <div class="progress-bar px-2 pe-lg-0 pb-2 position-relative w-75 w-lg-100 mx-auto">
            <div class="line d-none d-md-block"></div>
            
            <!-- visible on mobile -->
            <div class="d-flex align-items-center mb-3 d-lg-none mx-auto" v-if="patientStore.step == 'personal-details'">
                <div class="">
                    <img :src="personal">
                </div>
                <div class="ms-3 text-dark-blue fs-15px">{{ langStore.lang.views.personal_details }}</div>
            </div>
            <div class="d-flex align-items-center mb-3 d-lg-none mx-auto" v-if="patientStore.step == 'clinical-details'">
                <div class="">
                    <img :src="clinical">
                </div>
                <div class="ms-3 text-dark-blue fs-15px">{{ langStore.lang.views.clinical_details }}</div>
            </div>
            <div class="d-flex align-items-center mb-3 d-lg-none mx-auto" v-if="patientStore.step == 'care-plan'">
                <div class="">
                    <img :src="carePlan">
                </div>
                <div class="ms-3 text-dark-blue fs-15px">{{ langStore.lang.views.patient_care_plan }}</div>
            </div>
            <div class="d-flex align-items-center mb-3 d-lg-none mx-auto" v-if="patientStore.showPhysio && patientStore.step == 'physio'">
                <div class="">
                    <img :src="physio">
                </div>
                <div class="ms-3 text-dark-blue fs-15px">{{ langStore.lang.views.physiotherapy }}</div>
            </div>
            <div class="d-flex align-items-center mb-3 d-lg-none mx-auto" v-if="patientStore.step == 'caregiver-details'">
                <div class="">
                    <img :src="caregiver">
                </div>
                <div class="ms-3 text-dark-blue fs-15px">{{ langStore.lang.views.caregiver_details }}</div>
            </div>
            <!----->
            <div class="d-flex justify-content-between d-lg-block">
                <div class="d-flex mb-lg-4 align-items-center progress-item" :class="{'active' : patientStore.step == 'personal-details', 'completed' : ['clinical-details', 'care-plan', 'physio', 'caregiver-details'].includes(patientStore.step)}">
                    <div class="indicator-circle"></div>
                    <div class="mx-3 text-gray3 fs-15px d-none d-lg-block title">{{ langStore.lang.views.personal_details }}</div>
                    <div class="ms-auto d-none d-lg-block pe-3">
                        <img :src="personal">
                    </div>
                </div>
                <div class="d-flex mb-lg-4 align-items-center progress-item" :class="{'active' : patientStore.step == 'clinical-details', 'completed' : ['care-plan', 'physio', 'caregiver-details'].includes(patientStore.step)}">
                    <div class="indicator-circle"></div>
                    <div class="mx-3 text-gray3 fs-15px d-none d-lg-block title">{{ langStore.lang.views.clinical_details }}</div>
                    <div class="ms-auto d-none d-lg-block pe-3">
                        <img :src="clinical">
                    </div>
                </div>
                <div class="d-flex mb-lg-4 align-items-center progress-item" :class="{'active' : patientStore.step == 'care-plan', 'completed' : ['physio', 'caregiver-details'].includes(patientStore.step)}">
                    <div class="indicator-circle"></div>
                    <div class="mx-3 text-gray3 fs-15px d-none d-lg-block title">{{ langStore.lang.views.patient_care_plan }}</div>
                    <div class="ms-auto d-none d-lg-block pe-3">
                        <img :src="carePlan">
                    </div>
                </div>
                <div class="d-flex mb-lg-4 align-items-center progress-item" :class="{'active' : patientStore.step == 'physio', 'completed' : patientStore.step == 'caregiver-details'}" v-if="patientStore.showPhysio">
                    <div class="indicator-circle"></div>
                    <div class="mx-3 text-gray3 fs-15px d-none d-lg-block title">{{ langStore.lang.views.physiotherapy }}</div>
                    <div class="ms-auto d-none d-lg-block pe-3">
                        <img :src="physio">
                    </div>
                </div>
                <div class="d-flex align-items-center progress-item" :class="{'active' : patientStore.step == 'caregiver-details'}">
                    <div class="indicator-circle"></div>
                    <div class="mx-3 text-gray3 fs-15px d-none d-lg-block title">{{ langStore.lang.views.caregiver_details }}</div>
                    <div class="ms-auto d-none d-lg-block pe-3">
                        <img :src="caregiver">
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script setup>
import { usePatientStore } from '../../stores/patient';
import { useLangStore } from '../../stores/lang';
import personal from '@images/personal-details.svg'
import clinical from '@images/clinical-details.svg'
import carePlan from '@images/care-plan.svg'
import physio from '@images/physiotherapy.svg'
import caregiver from '@images/caregiver-details.svg'

const patientStore = usePatientStore();
const langStore = useLangStore()
</script>