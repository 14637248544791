import { Controller } from "@hotwired/stimulus"
// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Connects to data-controller="pre-op"
export default class extends Controller {
  connect() {
    this.swiper = new Swiper('.mySwiper', {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".swiper-pagination",
      }
    });
  }

  goToSlide(e){
    const slide = e.target.getAttribute('data-slide')
    const tabs = document.querySelectorAll('.pre-op-tab')

    tabs.forEach(tab => tab.classList.remove('active'))
    e.target.classList.add('active')
    
    this.swiper.slideTo(slide)
  }
}
