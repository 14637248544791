import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clinician-diary"
export default class extends Controller {

  static targets = ['input']

  connect() {
    this.scrollDown();

    document.addEventListener('turbo:submit-end', (e) => {
      setTimeout(() => {
        this.scrollDown();
      }, 200)
    })
  }

  submitNote(e){
    if(this.inputTarget.value.trim() == "") e.preventDefault();
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      which: 13,
      keyCode: 13,
    });
    setTimeout(() => {
      this.clearAfterEnter(event)
    }, 100)
  }

  clearAfterEnter(event) {
    if (event.key === "Enter") {
      this.inputTarget.value = ""
    }
  }

  scrollDown(){
    const chatBody = document.getElementById('chat-body')
    chatBody.scrollTop = chatBody.scrollHeight;
  }
}
