import { Application } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input";
import { StreamActions } from "@hotwired/turbo"
import { Tooltip } from 'bootstrap'
import "@lottiefiles/lottie-player";

const application = Application.start()
// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

initIntlTelInput();

StreamActions.redirect = function () {
    // window.location.href = this.target;
    Turbo.visit(this.target)
};

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

export { application }