const createUpdateUrlParam = (key, value) => {
    // Get the current URL
    let url = new URL(window.location);

    // Get query params
    let params = new URLSearchParams(url.search);

    // Update or create a new parameter
    params.set(key, value); // 'key' is the parameter name, 'value' is its value

    // Update the URL without reloading the page
    window.history.replaceState({}, '', `${url.pathname}?${params}`);
}

export { createUpdateUrlParam }