import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hospital-at-home"
export default class extends Controller {
  connect() {
  }

  expandTile(e){
    const id = e.currentTarget.getAttribute('data-target')
    const element = document.getElementById(id)
    const expanded = element.classList.contains('expanded')
    if(expanded){
      element.classList.remove('expanded')
    }else element.classList.add('expanded');
  }
}
