<template>
    <div class="p-4 text-start">
        <h6 class="text-dark-blue mb-4 mt-2 fs-15px">{{ langStore.lang.views.patient_personal_details }}</h6>
        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label for="nhsnumber" class="form-label modal-label" :class="{'required' : patientStore.nhsValidationEnabled}">{{ langStore.lang.views.health_identity_label }} <span v-if="!patientStore.nhsValidationEnabled">({{ langStore.lang.views.optional }})</span></label>
                <div class="input-group">
                    <input type="text" v-model="patientStore.formData.nhsnumber" name="nhsnumber" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.nhsnumber}" :placeholder="langStore.lang.views.health_identity_number_placeholder">
                    
                    <button 
                        v-if="patientStore.nhsEnabled"
                        class="btn btn-light border border-1 input-border-radius" 
                        type="button" 
                        data-bs-toggle="tooltip" 
                        :data-bs-title="langStore.lang.views.search_nhs_tooltip" 
                        :disabled="patientStore.formData.nhsnumber?.length != 10"
                        @click="searchNHS"
                    >
                        <img :src="searchIcon" ref="search">
                        <div class="spinner-sm spinner-light spinner-right" style="width:15px;margin-right:-2px;display:none" ref="loading"></div>
                    </button>
                </div>
                <span class="text-danger fs-12px" v-if="patientStore.errors.nhsnumber">{{ patientStore.errors.nhsnumber[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="hospitalnumber" class="form-label modal-label">{{ langStore.lang.views.hospital_number_label }}</label>
                <input type="text" id="hospitalnumber" v-model="patientStore.formData.hospitalnumber" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.hospitalnumber}" :placeholder="langStore.lang.views.hospital_number_placeholder">
                <span class="text-danger fs-12px" v-if="patientStore.errors.hospitalnumber">{{ patientStore.errors.hospitalnumber[0] }}</span>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label for="firstname" class="form-label modal-label required">{{ langStore.lang.views.first_name }}</label>
                <input type="text" name="firstname" v-model="patientStore.formData.firstname" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.firstname}" :placeholder="langStore.lang.views.enter_first_name">
                <span class="text-danger fs-12px" v-if="patientStore.errors.firstname">{{ patientStore.errors.firstname[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="lastname" class="form-label modal-label required">{{ langStore.lang.views.last_name }}</label>
                <input type="text" name="lastname" v-model="patientStore.formData.lastname" class="form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.lastname}" :placeholder="langStore.lang.views.enter_last_name">
                <span class="text-danger fs-12px" v-if="patientStore.errors.lastname">{{ patientStore.errors.lastname[0] }}</span>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label class="form-label modal-label required">{{ langStore.lang.views.gender_label }}</label>
                <div class="d-flex mt-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input float-unset" type="radio" name="gender" id="inlineGenderRadio1" :value="1" v-model="patientStore.formData.gender">
                        <label class="form-check-label text-dark-blue fs-15px ms-1" for="inlineGenderRadio1">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input float-unset" type="radio" name="gender" id="inlineGenderRadio2" :value="2" v-model="patientStore.formData.gender">
                        <label class="form-check-label text-dark-blue fs-15px ms-1" for="inlineGenderRadio2">Female</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input float-unset" type="radio" name="gender" id="inlineGenderRadio3" :value="3" v-model="patientStore.formData.gender">
                        <label class="form-check-label text-dark-blue fs-15px ms-1" for="inlineGenderRadio3">Other</label>
                    </div>
                </div>
                <span class="text-danger fs-12px" v-if="patientStore.errors.gender">{{ patientStore.errors.gender[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="dateofbirth" class="form-label modal-label required">{{ langStore.lang.views.date_of_birth }}</label>
                <div data-controller="flatpickr">
                    <div class="input-group input-border-radius input-box-shadow" :class="{'is-invalid' : patientStore.errors.dateofbirth}">
                        <input type="text" id="dateofbirth" v-model="patientStore.formData.dateofbirth" ref="birthDate" class="form-control form-control-lg text-dark-blue light-bg enable-left-radius no-border-right fp_date" placeholder="01/01/2000">
                        <span class="input-group-text light-bg">
                            <img :src="calendarIcon">
                        </span>
                    </div>
                </div>
                <span class="text-danger fs-12px" v-if="patientStore.errors.dateofbirth">{{ patientStore.errors.dateofbirth[0] }}</span>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label for="gsm" class="form-label modal-label required">{{ langStore.lang.views.phone_number }}</label>
                <input type="text" name="gsm" ref="phoneInput" v-model="patientStore.formData.gsm" class="phone-input form-control form-control-lg text-dark-blue" :class="{'is-invalid' : patientStore.errors.gsm}">
                <span class="text-danger fs-12px" v-if="patientStore.errors.gsm">{{ patientStore.errors.gsm[0] }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <label for="email" class="form-label modal-label">{{ langStore.lang.views.email }} ({{ langStore.lang.views.optional }})</label>
                <input type="email" name="email" id="email" v-model="patientStore.formData.email" class="form-control form-control-lg text-dark-blue" :placeholder="langStore.lang.views.enter_email_address">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-lg-6">
                <div class="d-flex">
                    <div class="me-2">
                        <label for="height" class="form-label modal-label">{{ langStore.lang.views.height }}</label>
                        <div class="d-flex align-items-center">
                            <input type="number" v-model="patientStore.formData.height" class="form-control form-control-lg text-dark-blue">
                            <div class="text-dark-blue ms-2">cm</div>
                        </div>
                    </div>
                    <div class="ms-2">
                        <label for="height" class="form-label modal-label">{{ langStore.lang.views.weight }}</label>
                        <div class="d-flex align-items-center">
                            <input type="number" name="weight" v-model="patientStore.formData.weight" class="form-control form-control-lg text-dark-blue">
                            <div class="text-dark-blue ms-2">kg</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { usePatientStore } from '../../stores/patient';
import { useLangStore } from '../../stores/lang';
import searchIcon from '@images/search-icon-gray.svg'
import calendarIcon from '@images/calendar.svg'

const patientStore = usePatientStore();
const langStore = useLangStore();
const birthDate = ref(null)
const search = ref(null)
const loading = ref(null)
const phoneInput = ref(null)

const searchNHS = async () => {
    patientStore.errors = {}
    patientStore.formData.firstname = null
    patientStore.formData.lastname = null
    patientStore.formData.birthdate = null
    birthDate.value._flatpickr.setDate(null)
    patientStore.formData.gender = null

    search.value.style.display = 'none'
    loading.value.style.display = 'block'

    await fetch('/patients/nhs_details?nhsnumber='+patientStore.formData.nhsnumber, {
      method : "GET",
      headers: {
        'Accept' : 'application/json'
      }
    })
    .then(response => response.text())
    .then(response => JSON.parse(response))
    .then(data => {
      const name = data.name.find(item => item.use == 'usual')
      const firstName = name?.given[0]
      const lastName = name?.family
      const dob = data.birthDate
      const gender = data.gender == 'male' ? 1 : (data.gender == 'female' ? 2 : 3); 

      if(data.id.trim() != patientStore.formData.nhsnumber.trim()){
        patientStore.errors.nhsnumber = [langStore.lang.views.nhs_mismatch_error.replace('old_nhs', patientStore.formData.nhsnumber).replace('new_nhs', data.id)]
        patientStore.formData.nhsnumber = data.id
      }

      patientStore.formData.firstname = firstName
      patientStore.formData.lastname = lastName
      patientStore.formData.birthdate = dob
      birthDate.value._flatpickr.setDate(new Date(dob))
      patientStore.formData.gender = gender

    }).catch((e) => {
        patientStore.errors.nhsnumber = [langStore.lang.views.unable_to_find_patient]
    })

    search.value.style.display = 'block'
    loading.value.style.display = 'none'
  }

  defineExpose({
    phoneInput
  })
</script>