<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5" v-html="langStore.lang.sample_taken_question_html"></div>
        <div class="d-flex justify-content-between mb-5">
            <button class="btn btn-white rio-pdq-button w-165px me-1" :class="{'active' : store.formData.health_worker_took_sample === true}" @click="select(true)">{{ langStore.lang.yes_answer }}</button>
            <button class="btn btn-white rio-pdq-button w-165px ms-1" :class="{'active' : store.formData.health_worker_took_sample === false}" @click="select(false)">{{ langStore.lang.no_answer }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';

const store = usePdqStore();
const langStore = useLangStore();

const select = (value) => {
    store.formData.health_worker_took_sample = value
    store.nextStep();
}
</script>