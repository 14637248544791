import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// Connects to data-controller="hah-consciouness-level"
export default class extends Controller {

  static values = {
    scores: {
      'A' : 0,
      'B' : 1,
      'C' : 2,
      'D' : 3,
      'E' : 4
    },
    scoreNames: {
      '0' : 'Alert',
      '1' : 'Drowsy',
      '2' : 'Confused',
      '3' : 'Stupor',
      '4' : 'Coma'
    }
  }

  connect() {
    this.makeConsciounessLevelChart()
    this.series = {}
  }

  handleInputLimit(e){
    e.target.value = e.target.value.toUpperCase()
    if(e.target.value.length > 0) {
      e.target.value = e.target.value.substr(0, 0);
    }
  }

  addConsciounessLevel(e){
    const patient_id = Number(this.element.getAttribute('data-patient-id'))
    const csrf = document.querySelector('meta[name="csrf-token"]')
    fetch('/patients/'+patient_id+'/add_consciouness_level', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf.content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({consciouness_level: e.target.value.toUpperCase()})
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  makeConsciounessLevelChart(){
    am5.ready(async () => {

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = am5.Root.new("consciouness-level-chart");

      this.root._logo.dispose();
      
      this.myTheme = am5.Theme.new(this.root);
      
      // Move minor label a bit down
      this.myTheme.rule("AxisLabel", ["minor"]).setAll({
        dy: 1
      });
      
      // Tweak minor grid opacity
      this.myTheme.rule("Grid", ["minor"]).setAll({
        strokeOpacity: 0.08
      });
      
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      this.root.setThemes([
        am5themes_Animated.new(this.root),
        this.myTheme
      ]);
      
      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0
      }));
      
      
      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      this.cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
        behavior: "zoomX"
      }));
      this.cursor.lineY.set("visible", false);
      
      this.xRenderer = am5xy.AxisRendererX.new(this.root, {
        minorGridEnabled: true,
        minGridDistance:30,    
        minorLabelsEnabled: true
      });
      this.xRenderer.grid.template.set("forceHidden", true);
      this.xRenderer.labels.template.setAll({
        fill: am5.color(0x81868B),
        fontSize: "11px"
      });

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      this.xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
        groupData: true,
        maxDeviation: 0.5,
        baseInterval: { timeUnit: "minute", count: 1 },
        gridIntervals: [
          { timeUnit: "day", count: 1 },
          { timeUnit: "month", count: 1 }
        ],
        renderer: this.xRenderer,
      }));
      
      this.xAxis.set("minorDateFormats", {
        day: "dd",
        month: "MMM",
        hour: "HH",
        minute: "mm",
      });

      this.xAxis.onPrivate("cellWidth", (cellWidth) => {
        let rotated = false;
        this.xRenderer.labels.each(function(label) {
          if (label.width() > cellWidth) {
            rotated = true;
          }
        });
        
        if (rotated) {
          this.xRenderer.labels.template.setAll({
            rotation: -45,
            centerX: am5.p100
          });
        }
        else {
          this.xRenderer.labels.template.setAll({
            rotation: 0,
            centerX: am5.p50
          });
        }
      });

      this.yRenderer = am5xy.AxisRendererY.new(this.root, {
        minGridDistance: 2
      });
      this.yRenderer.grid.template.set("strokeDasharray", [3]);
      this.yRenderer.labels.template.setAll({
        fill: am5.color(0x81868B),
        fontSize: "11px",
      });
      
      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
        maxPrecision: 0,
        renderer: this.yRenderer,
        min: -0.1
      }));

      await this.formatChartData();

      this.createSeries(this.chartData, 'Consciouness Level', 'value', '#ED0000')
      
      await this.chart.appear(0)

      this.groupData('month')

      this.yAxis.get("renderer").labels.template.adapters.add("text", (text, target) => {
        return this.scoreNamesValue[text];
      });
      
      }); // end am5.ready()
  }

  formatChartData(){
    const data = JSON.parse(this.element.getAttribute('data'))  
    this.chartData = data.map(item => {
      return { 
        date: (new Date(item.taken_at)).getTime(),
        value: this.scoresValue[item.value.toUpperCase()]
      }
    })
  }

  createSeries(data, name, field, color) {
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series[field] = this.chart.series.push(am5xy.LineSeries.new(this.root, {
      name: name,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueYField: field,
      valueXField: "date",
      valueYGrouped: "average"
    }));

    this.series[field].strokes.template.setAll({
      strokeWidth: 3,
    });

    this.series[field].set('fontSize', 11)
    this.series[field].set('fill', am5.color(color))
    this.series[field].set('stroke', am5.color(color))
    
    // Actual bullet
    this.series[field].bullets.push(() => {
      var bulletCircle = am5.Circle.new(this.root, {
        radius: 5,
        fill: this.series[field].get("fill"),
        fill: this.root.interfaceColors.get("background"),
        stroke: am5.color('#A09664'),
        strokeWidth: 2
      });

      return am5.Bullet.new(this.root, {
        sprite: bulletCircle,
        zIndex: 5
      })
    })

    this.series[field].data.setAll(data)
  }

  handleGroupData(e){
    e.target.parentElement.querySelector('a.active').classList.remove('active')
    e.target.classList.add('active')

    const interval = e.target.getAttribute('data-interval')

    this.groupData(interval)
  }

  async groupData(interval){
    const config = {
      'hour' : {
        days: 1,
        filterInterval: 'hour',
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY, HH:mm')}h[/]\n[fontSize: 14px; fontWeight: bold; #1F2E99]{valueY}[fontSize: 10px]"
      },
      'week' : {
        days: 7,
        filterInterval: 'day',
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY')}[/]\n[fontSize: 14px; fontWeight: bold; #1F2E99]{valueY}[fontSize: 10px]"
      },
      'month' : {
        days: 30,
        filterInterval: 'day',
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY')}[/]\n[fontSize: 14px; fontWeight: bold; #1F2E99]{valueY}[fontSize: 10px]"
      },
      'six-months' : {
        days: 183,
        filterInterval: 'month',
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('MMM YYYY')}[/]\n[fontSize: 14px; fontWeight: bold; #1F2E99]{valueY}[fontSize: 10px]"
      },
      'year' : {
        days: 365,
        filterInterval: 'month',
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('MMM YYYY')}[/]\n[fontSize: 14px; fontWeight: bold; #1F2E99]{valueY}[fontSize: 10px]"
      }
    }

    const date = (new Date()).getTime() - (24 * 60 * 60 * 1000) * config[interval].days;
    const filtered = this.chartData.filter((item) => (new Date(item.date)).getTime() >= date);

    await this.xAxis.set('groupInterval', { timeUnit: config[interval].filterInterval, count: 1 })

    for await (const serie of Object.values(this.series)) {
      let seriesTooltip = am5.Tooltip.new(this.root, {
        getFillFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        labelText: config[interval].tooltipText
      })
  
      seriesTooltip.get("background").setAll({
        fill: am5.color(0xffffff),
        fillOpacity: 1,
        stroke: am5.color(0xDDE3E7)
      });
  
      seriesTooltip.label.setAll({
        fill: am5.color(0x000000)
      }); 

      seriesTooltip.label.adapters.add("text", (text, target) => {
        const dataItem = serie.get("tooltipDataItem")
        if(dataItem){
          return text.replace('{valueY}', this.scoreNamesValue[Math.round(dataItem.get("valueY")).toString()])
        }
      });

      serie.set('tooltip', seriesTooltip)
      
      await this.chart.appear(1)

      if(filtered.length > 0){
        this.xRenderer.labels.template.set('visible', true)
        serie.show(1)
        serie.data.setAll(filtered)
      }else {
        this.xRenderer.labels.template.set('visible', false)
        serie.hide(1)
      }
    }
  }
}
