import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// Connects to data-controller="hah-heart-rate"
export default class extends Controller {
  connect() {
    this.makeHeartRateChart()
  }

  makeHeartRateChart() {
    am5.ready(async () => {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = am5.Root.new("heart-rate-chart");

      this.root._logo.dispose();

      this.root.numberFormatter.setAll({
        numberFormat: "#,###.",
        numericFields: ["valueY"]
      });
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0
      }));

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      this.cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
      this.cursor.lineY.set("visible", false);


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      this.xRenderer = am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 30,
        minorGridEnabled: true
      });
      this.xRenderer.labels.template.setAll({
        centerY: am5.p50,
        centerX: 0,
        fill: am5.color(0x81868B),
        fontSize: "11px"
      });

      this.xRenderer.grid.template.setAll({
        visible: false
      })

      this.xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
        groupData: true,
        maxDeviation: 0.5,
        baseInterval: { timeUnit: "minute", count: 1 },
        gridIntervals: [
          { timeUnit: "day", count: 1 },
          { timeUnit: "month", count: 1 }
        ],
        categoryField: "date",
        renderer: this.xRenderer
      }));

      this.xAxis.onPrivate("cellWidth", (cellWidth) => {
        let rotated = false;
        this.xRenderer.labels.each(function (label) {
          if (label.width() > cellWidth) {
            rotated = true;
          }
        });

        if (rotated) {
          this.xRenderer.labels.template.setAll({
            rotation: -45,
            centerX: am5.p100
          });
        }
        else {
          this.xRenderer.labels.template.setAll({
            rotation: 0,
            centerX: am5.p50
          });
        }
      });

      this.yRenderer = am5xy.AxisRendererY.new(this.root, {})

      this.yRenderer.labels.template.setAll({
        fill: am5.color(0x81868B),
        fontSize: "11px"
      })

      this.yRenderer.grid.template.set("strokeDasharray", [3]);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: this.yRenderer
      }));

      await this.formatChartData()
      this.groupData('month')
      this.createSeries()

    }); // end am5.ready()
  }

  formatChartData() {
    const data = JSON.parse(this.element.getAttribute('data'))
    this.chartData = data.map(item => {
      return {
        date: (new Date(item.taken_at)).getTime(),
        value: Number(item.value)
      }
    })
  }

  createSeries() {
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Heart rate",
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueYField: "max",
      openValueYField: "min",
      valueXField: "date",
      valueYGrouped: "extreme"
    }));
    this.series.columns.template.setAll({
      width: 4,
      fill: am5.color(0XFE35C6),
      stroke: am5.color(0XFE35C6),
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      minHeight: 5
    });


    this.xAxis.data.setAll(this.chartData);
    this.series.data.setAll(this.chartData);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    this.series.appear(1000);
    this.chart.appear(1000, 100);
  }

  handleGroupData(e) {
    e.target.parentElement.querySelector('a.active').classList.remove('active')
    e.target.classList.add('active')

    const interval = e.target.getAttribute('data-interval')

    this.groupData(interval)
  }

  async groupData(interval) {

    const createFilteredData = async (days, unit, dateAdjuster) => {
      const data = [];

      for (let i = days - 1; i >= 0; i--) {
        let pastDate = new Date();
        dateAdjuster(pastDate, i);
        let formattedDate = pastDate;

        const res = this.chartData.filter(item => {
          const d = new Date(item.date);
          return unit.every(u => d[u.method]() === formattedDate[u.method]());
        });

        if (res.length > 0) {
          data.push({
            date: res[0].date,
            min: Math.min(...res.map(item => item.value)),
            max: Math.max(...res.map(item => item.value))
          });
        }
      }

      return data;
    };

    const config = {
      'hour': {
        days: 1,
        filterInterval: 'hour',
        filtered: await createFilteredData(24, [{ method: 'getHours' }, { method: 'getDate' }, { method: 'getMonth' }, { method: 'getFullYear' }],
          (date, i) => date.setHours(date.getHours() - i)),
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY, HH:mm')}h\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99] bpm"
      },
      'week': {
        days: 7,
        filterInterval: 'day',
        filtered: await createFilteredData(7, [{ method: 'getDate' }, { method: 'getMonth' }, { method: 'getFullYear' }],
          (date, i) => date.setDate(date.getDate() - i)),
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY')}\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99] bpm"
      },
      'month': {
        days: 30,
        filterInterval: 'day',
        filtered: await createFilteredData(30, [{ method: 'getDate' }, { method: 'getMonth' }, { method: 'getFullYear' }],
          (date, i) => date.setDate(date.getDate() - i)),
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('dd MMM YYYY')}\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99] bpm"
      },
      'six-months': {
        days: 183,
        filterInterval: 'month',
        filtered: await createFilteredData(183, [{ method: 'getMonth' }, { method: 'getFullYear' }],
          (date, i) => date.setDate(date.getDate() - i)),
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('MMM YYYY')}\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99] bpm"
      },
      'year': {
        days: 365,
        filterInterval: 'month',
        filtered: await createFilteredData(365, [{ method: 'getMonth' }, { method: 'getFullYear' }],
          (date, i) => date.setDate(date.getDate() - i)),
        tooltipText: "[fontSize: 12px; #ACB4BC]{valueX.formatDate('MMM YYYY')}\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99] bpm"
      }
    };

    this.xAxis.set('groupInterval', { timeUnit: config[interval].filterInterval, count: 1 })

    let seriesTooltip = am5.Tooltip.new(this.root, {
      getFillFromSprite: false,
      autoTextColor: false,
      pointerOrientation: "horizontal",
      labelText: config[interval].tooltipText
    })

    seriesTooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: am5.color(0xDDE3E7)
    });

    seriesTooltip.label.setAll({
      fill: am5.color(0x000000)
    });

    this.series.set('tooltip', seriesTooltip)

    this.series.get('tooltip').adapters.add("labelText", function (text, target) {
      if (target.dataItem && target.dataItem.dataContext.min == target.dataItem.dataContext.max) {
        return text.replace("{openValueY} - ", "")
      }
      return text;
    });


    if (config[interval].filtered.length > 0) {
      this.xRenderer.labels.template.set('visible', true)
      this.series.show(1)
      this.series.data.setAll(config[interval].filtered)
    } else {
      this.xRenderer.labels.template.set('visible', false)
      this.series.hide(1)
    }

  }
}
