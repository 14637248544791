import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clinician-details"
export default class extends Controller {

  static targets = ["resendInvitationForm"]

  connect() {
  }

  resendInvitation(){
    this.resendInvitationFormTarget.requestSubmit();
  }
}
