import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { start } from "@popperjs/core";

// Connects to data-controller="recovery-timeline"
export default class extends Controller {

  static values = {
    scoreNames: {
      '2': 'Good',
      '1': 'Fair',
      '0': 'Poor'
    },
    scoreMap: {
      0: 2,
      1: 1,
      2: 0
    },
    colors: {
      0: '#C1180C',
      1: '#FFE586',
      2: '#00683C'
    }
  }

  connect() {
    this.makeChart()
  }

  makeChart() {
    am5.ready(async () => {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = am5.Root.new("recovery-timeline-chart");

      this.root._logo.dispose();

      this.root.numberFormatter.setAll({
        numberFormat: "#,###.",
        numericFields: ["valueY"]
      });
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0
      }));

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      this.cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
      this.cursor.lineY.set("visible", false);


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      this.xRenderer = am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 30,
        minorGridEnabled: true
      });
      this.xRenderer.labels.template.setAll({
        centerY: am5.p50,
        centerX: 0,
        fill: am5.color(0x81868B),
        fontSize: "11px"
      });

      this.xRenderer.grid.template.setAll({
        visible: false
      })

      this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0.5,
        categoryField: "day",
        renderer: this.xRenderer
      }));

      this.xAxis.onPrivate("cellWidth", (cellWidth) => {
        let rotated = false;
        this.xRenderer.labels.each(function (label) {
          if (label.width() > cellWidth) {
            rotated = true;
          }
        });

        if (rotated) {
          this.xRenderer.labels.template.setAll({
            rotation: -45,
            centerX: am5.p100
          });
        }
        else {
          this.xRenderer.labels.template.setAll({
            rotation: 0,
            centerX: am5.p50
          });
        }
      });

      this.yRenderer = am5xy.AxisRendererY.new(this.root, {})


      this.yRenderer.labels.template.setAll({
        fill: am5.color(0x81868B),
        fontSize: "11px"
      })

      this.yRenderer.grid.template.set("strokeDasharray", [3]);

      this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        maxPrecision: 0,
        renderer: this.yRenderer,
        min: -0.1
      }));

      this.yAxis.get("renderer").labels.template.adapters.add("text", (text, target) => {
        return this.scoreNamesValue[text];
      });

      this.xAxis.get("renderer").labels.template.adapters.add("text", (text, target) => {
        return 'D' + text
      });

      await this.formatChartData()
      this.createSeries(0)
      this.createTooltip()

    }); // end am5.ready()
  }

  formatChartData() {
    let data = JSON.parse(this.element.getAttribute('data-items'))

    data = Object.groupBy(data, ({ dayno }) => dayno);

    const res = {};

    for (const [key, value] of Object.entries(data)) {

      const values = [...value.map(i => {
        return this.scoreMapValue[i.recovery_status]
      })]

      value.forEach(_ => {
        res[key] = {
          day: _.dayno,
          min: Math.min(...values),
          max: Math.max(...values)
        }
      })
    }

    this.chartData = Object.values(res)
  }

  createSeries(val) {
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series = this.chart.series.push(am5xy.ColumnSeries.new(this.root, {
      name: "Recovery Timeline",
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      valueYField: "max",
      openValueYField: "min",
      categoryXField: "day",
    }));
    this.series.columns.template.setAll({
      width: 8,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      minHeight: 15,
      strokeOpacity: 0
    });

    this.xAxis.data.setAll(this.chartData);
    this.series.data.setAll(this.chartData);

    this.series.columns.template.adapters.add("fill", (fill, target) => {

      const valueY = target.dataItem.get("valueY")
      const openValueY = target.dataItem.get("openValueY")

      if (valueY == openValueY) {
        return am5.color(this.colorsValue[valueY]);
      }
    });

    this.series.columns.template.adapters.add("fillGradient", (fillGradient, target) => {

      const valueY = target.dataItem.get("valueY")
      const openValueY = target.dataItem.get("openValueY")

      const stops = [];

      for (let i = valueY; i >= openValueY; i--) {
        stops.push({
          color: am5.color(this.colorsValue[i])
        })
      }

      if (valueY != openValueY) {
        return am5.LinearGradient.new(this.root, {
          stops: stops
        })
      }
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    this.series.appear(1000);
    this.chart.appear(1000, 100);
  }

  createTooltip() {
    let seriesTooltip = am5.Tooltip.new(this.root, {
      getFillFromSprite: false,
      autoTextColor: false,
      pointerOrientation: "horizontal",
      labelText: "[fontSize: 12px; #ACB4BC]{categoryX}\n[fontSize: 14px; fontWeight: bold; #1F2E99]{openValueY} - {valueY} [fontSize: 10px; #1F2E99]"
    })

    seriesTooltip.get("background").setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: am5.color(0xDDE3E7)
    });

    seriesTooltip.label.setAll({
      fill: am5.color(0x000000)
    });

    seriesTooltip.label.adapters.add("text", (text, target) => {
      const dataItem = this.series.get("tooltipDataItem")
      if (dataItem) {
        const startVal = dataItem.get("openValueY")
        const endVal = dataItem.get("valueY")
        const day = dataItem.get("categoryX")

        let res = text.replace('{categoryX}', 'Day ' + day + ' Range')

        if (startVal == endVal) {
          return res.replace('{openValueY}', this.scoreNamesValue[startVal.toString()]).replace(' - {valueY}', '')
        } else {
          return res.replace('{openValueY}', this.scoreNamesValue[startVal.toString()]).replace('{valueY}', this.scoreNamesValue[endVal.toString()])

        }
      }
    });

    this.series.set('tooltip', seriesTooltip)
  }
}
