import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ukhsa"
export default class extends Controller {
  connect() {
    Turbo.cache.exemptPageFromPreview()
  }

  pdqCompletedChanged(e){
    const pdqCompletedDateContainer = document.getElementById('pdq-completed-container')

    if(e.target.value == '1' && e.target.checked){
      pdqCompletedDateContainer.classList.remove('d-none')
    }

    if(e.target.value == '0' && e.target.checked){
      pdqCompletedDateContainer.classList.add('d-none')
    }
  }

  patientReviewedChanged(e){
    const patientReviewedDateContainer = document.getElementById('patient-reviewed-container')

    if(e.target.value == '1' && e.target.checked){
      patientReviewedDateContainer.classList.remove('d-none')
    }

    if(e.target.value == '0' && e.target.checked){
      patientReviewedDateContainer.classList.add('d-none')
    }
  }
}
