import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-call-notes"
export default class extends Controller {

  static targets = ['form']

  connect() {
    
  }

  showNotes(){
    const el = document.getElementById('call-notes')
    el.classList.remove('d-none')
  }

  hideNotes(){
    const el = document.getElementById('call-notes')
    el.classList.add('d-none')
  }

  submit(){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 1000)
  }
}
