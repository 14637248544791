import { Controller } from "@hotwired/stimulus"
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from "@amcharts/amcharts5/percent";

// Connects to data-controller="dashboard"
export default class extends Controller {

  static targets = ['barChart', 'donutChart', 'rangePicker', 'selectedOutcomeOption'];

  static values = ['barChartRoot', 'donutChartRoot']

  connect() {
    this.drawCharts();
  }

  getOutcomeScores(option, range){
    fetch('/dashboard/outcome_scores?selected_option='+option+'&selected_range='+range, {
        method : "GET"
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(() => {
      setTimeout(() => {
        this.generateBarChart(this.barChartTarget);
      }, 100)
    })
  }

  getPainScores(range){
    fetch('/dashboard/pain_scores?selected_range='+range, {
        method : "GET"
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(() => {
      setTimeout(() => {
        this.generateDonutChart(this.donutChartTarget);
      }, 100)
    })
  }

  questionaireSelected(e){
    const option = e.target.getAttribute('data-option')
    const range = this.rangePickerTargets.find(item => item.classList.contains('active')).getAttribute('data-value')
    this.getOutcomeScores(option, range)
  }

  outcomeRangeSelected(e){
    const range = e.target.getAttribute('data-value')
    const option = this.selectedOutcomeOptionTarget.value
    this.getOutcomeScores(option, range)
  }

  generateBarChart(element){
    const existingChart = element.querySelector('div')
    if(existingChart) existingChart.remove();
    this.barChartRootValue?.dispose()
    const columns = JSON.parse(element.getAttribute('data-columns'));
    const categoryField = element.getAttribute('data-category-field');
    const data = JSON.parse(element.getAttribute('data-data'));
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.barChartRootValue = am5.Root.new(element);
      this.barChartRootValue._logo.dispose();
      this.barChartRootValue.numberFormatter.set("numberFormat", "#,###.00"); 
      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = this.barChartRootValue.container.children.push(am5xy.XYChart.new(this.barChartRootValue, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        // layout: root.verticalLayout
      }));  
      
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(this.barChartRootValue, {
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        minorGridEnabled: true
      })

      xRenderer.grid.template.set("visible", false);
      xRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      xRenderer.labels.template.set("fontSize", 14);
      
      var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.barChartRootValue, {
        categoryField: categoryField,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.barChartRootValue, {})
      }));
      
      xAxis.data.setAll(data);

      var yRenderer = am5xy.AxisRendererY.new(this.barChartRootValue, {
        strokeOpacity: 0.1,
        forceHidden: true
      })

      yRenderer.labels.template.set("fill", am5.color(0xACB4BC));
      yRenderer.labels.template.set("fontSize", 14);
      
      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.barChartRootValue, {
        renderer: yRenderer
      }));

      // yRenderer.labels.template.set("fill", series.get("fill"));
      
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      const makeSeries = (name, fieldName, color) => {
        var series = chart.series.push(am5xy.ColumnSeries.new(this.barChartRootValue, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField:categoryField,
        }));
      
        series.columns.template.setAll({
          tooltipText: "{categoryX}: {valueY}",
          width: 16,
          tooltipY: 0,
          strokeOpacity: 0,
          maxWidth: 16,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          fill: am5.color(color),
        });
      
        series.data.setAll(data);
      
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
      
        series.bullets.push(() => {
          return am5.Bullet.new(this.barChartRootValue, {
            locationY: 0,
            sprite: am5.Label.new(this.barChartRootValue, {
              text: "{valueY}",
              fill: this.barChartRootValue.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          });
        });
      
      }

      const colors = {
        purple: 0x9E84FF,
        blue: 0x81BCFF
      }
      
      for(let i=0;i<columns.length;i++){
        makeSeries(columns[i].title, columns[i].key, colors[columns[i].color]);
      }
      
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

  }

  painRangeSelected(e){
    const range = e.target.getAttribute('data-value')
    this.getPainScores(range)
  }

  generateDonutChart(element){
    const existingChart = element.querySelector('div')
    if(existingChart) existingChart.remove();
    this.donutChartRootValue?.dispose()

    const data = JSON.parse(element.getAttribute('data-data'));
    const totalQuestionairesLang = element.getAttribute('data-total-questionaries-translate')

     // Create root and chart
      this.donutChartRootValue = am5.Root.new(element);
      this.donutChartRootValue._logo.dispose();
      this.donutChartRootValue.setThemes([
        am5themes_Animated.new(this.donutChartRootValue)
      ]);

      var chart = this.donutChartRootValue.container.children.push( 
        am5percent.PieChart.new(this.donutChartRootValue, {
          layout: this.donutChartRootValue.horizontalLayout,
          radius: am5.percent(90),
          innerRadius: am5.percent(70)
        }) 
      );

      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(this.donutChartRootValue, {
          name: "Series",
          valueField: "total",
          categoryField: "stattype",
          clickTarget: "none"
        })
      );
      series.slices.template.set("toggleKey", "none");
      series.labels.template.set("forceHidden", true);
      series.ticks.template.set("forceHidden", true);
      series.get("colors").set("colors", [
        am5.color("#A7E36C"),
        am5.color("#CEFF67"),
        am5.color("#F3D953"),
        am5.color("#F3B352"),
        am5.color("#FFA984"),
        am5.color("#FF7A72")
      ]);

      const totalQuestionaires = data.reduce((accumulator ,item) => {
        return accumulator += item.total;
      }, 0)

      var label = series.children.push(am5.Label.new(this.donutChartRootValue, {
        html: "<div><div class='pain-scores-circle-title'>"+totalQuestionaires+"</div><div class='pain-scores-circle-subtitle'>"+totalQuestionairesLang+"</div></div>",
        fontSize: 40,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        oversizedBehavior: "fit"
      }));

      series.onPrivate("width", function(width) {
        label.set("maxWidth", width * 0.7);
      });

      series.data.setAll(data);  

      const tooltip = am5.Tooltip.new(this.donutChartRootValue, {
        getFillFromSprite: false,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        // labelHTML: tooltipHTML
      })

      tooltip.get("background").setAll({
        fill: am5.color("#ffffff"),
        fillOpacity: 1,
        strokeOpacity: 0
      });

      series.set("tooltip", tooltip);

      series.get("tooltip").label.adapters.add("html", function(text, target) {
        // const patients = target.dataItem?.dataContext?.patients ?? []
        let tooltipHTML = "<div style='background:white;padding:15px;' class='border rounded'>"
          tooltipHTML += "<div class='d-flex align-items-start justify-content-between'>"
            tooltipHTML += "<div>"
              tooltipHTML += "<div class='text-dark-blue fw-bold fs-26px lh-23px'>{value}</div>"
              tooltipHTML += "<div class='text-gray8 fs-12px'>Patients</div>"
            tooltipHTML += "</div>"
            tooltipHTML += "<div class='legend-square {class}'></div>"
          tooltipHTML += "</div>"
          // tooltipHTML += "<div>"
          //   tooltipHTML += "<a class='d-flex text-decoration-none' href='#'>"
          //     patients.forEach(patient => {
          //       tooltipHTML += "<div class='dashboard-user-photo'>"+patient.initials+"</div>"
          //     })
          //     const surplus = Number(target.dataItem?.dataContext?.value) - patients.length
          //     if(surplus > 0){
          //       tooltipHTML += "<div class='dashboard-user-photo light3-bg text-gray6'>+"+surplus+"</div>"
          //     }
              
          //   tooltipHTML += "</a>"
          // tooltipHTML += "</div>"
        tooltipHTML += "</div>"
        return tooltipHTML;
      });
  }

  drawCharts(){
    for(let i = 0; i < this.barChartTargets.length; i++){
      this.generateBarChart(this.barChartTargets[i]);
    }

    for(let i = 0; i < this.donutChartTargets.length; i++){
      this.generateDonutChart(this.donutChartTargets[i]);
    }
  }
}
