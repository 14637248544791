import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import ApiService from "../services/api_service";
import { computed } from "vue";
import { usePatientStore } from "./patient";
import { Modal } from "bootstrap";
import { useLangStore } from "./lang";

export const usePhysioStore = defineStore('physio', () => {
    const addModal = ref(null)
    const editModal = ref(null)
    const bodyParts = ref([])
    const exercises = ref([])
    const formData = ref({})
    const currentExercise = ref({})
    const searchKeyword = ref('')

    const resetFormData = () => {
        formData.value = {
            name: null,
            desc: null,
            image: null,
            video: null,
            sets: null,
            reps: null
        }

        currentExercise.value = {...formData.value}
    }

    resetFormData()

    const patientStore = usePatientStore();

    const getExercisesForBodyParts = () => {
        return new Promise((resolve, reject) => {
            ApiService.post('/physio/template.json', { body_parts: bodyParts.value }).then(({ data }) => {
                exercises.value = data.exercises.map(exercise => {
                    exercise.completed = false
                    exercise.difficulty = null
                    exercise.enabled = true
                    return exercise
                })
                patientStore.formData.physio = generatePhysioPlan(patientStore.exerciseDays)
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const defaultExercises = computed(() => exercises.value.filter(exercise => exercise.default))

    // used for search of exercises
    const filteredExercises = computed(() => {
        return exercises.value.filter(item => {
            return !item.default && translate(item.lang_name).toLowerCase().indexOf(searchKeyword.value.toLowerCase()) != -1
        })
    })

    const assignedExerciseIds = computed(() => {
        const ids = [];
    
        for(const days of Object.values(patientStore.formData.physio)){
            for(const dayExercises of Object.values(days)){
                const exerciseIds = dayExercises.map(exercise => exercise.id)
                exerciseIds.forEach(id => {
                    if(!ids.includes(id)){
                        ids.push(id)
                    }
                })
            }
        }

        return ids;
    })

    const generatePhysioPlan = (days) => {
        const plan = {}
        let week = 1;

        for (let i = 0; i <= 365; i++) {
            if (!plan[week]) {
                plan[week] = {}
            }

            if(!plan[week][i]){
                plan[week][i] = {}
            }

            if(days.includes(i)){
                defaultExercises.value.forEach(exercise => {
                    plan[week][i][exercise.id] = {...exercise}
                })
            }

            if (i > 0 && i % 7 == 0) {
                week++;
            }
        }

        return plan;
    }

    const synchronizePhysioPlan = () => {
        for(const [week, days] of Object.entries(patientStore.formData.physio)){
            for(let [day, dayExercises] of Object.entries(days)){
                dayExercises = Object.values(dayExercises).filter(item => bodyParts.value.includes(item.body_part))
                
                patientStore.formData.physio[week][day] = {}
                
                if(patientStore.exerciseDays.includes(Number(day))){
                    defaultExercises.value.forEach(defaultExercise => {
                        const alreadyAdded = dayExercises.find(item => Number(item.id) == Number(defaultExercise.id))


                        if(!alreadyAdded){
                            dayExercises.push({...defaultExercise})
                        }
                    })
                    
                    dayExercises.forEach(exercise => {
                        patientStore.formData.physio[week][day][exercise.id] = {enabled: true, ...exercise};
                    }) 
                }
            }
        }
    }

    const initEditModal = (element) => {
        editModal.value = new Modal(element)
    }

    const initAddModal = (element) => {
        addModal.value = new Modal(element)
    }

    const translate = (key) => {
        const langStore = useLangStore();

        if (!key) return langStore.lang;
    
        let lang = {...langStore.lang}
    
        var prop, props = key.split('.');
    
        for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
            prop = props[i];
    
            var candidate = lang[prop];
            if (candidate !== undefined) {
                lang = candidate;
            } else {
            break;
            }
        }
        return lang[props[i]];
    }

    return {
        bodyParts,
        getExercisesForBodyParts,
        generatePhysioPlan,
        exercises,
        synchronizePhysioPlan,
        editModal,
        initEditModal,
        formData,
        resetFormData,
        currentExercise,
        searchKeyword,
        filteredExercises,
        translate,
        assignedExerciseIds,
        addModal,
        initAddModal
    }
})