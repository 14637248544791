<template>
    <div class="flex-fill d-flex flex-column">
        <div class="fs-24px fw-bold text-dark-blue mb-5"
            v-html="langStore.lang.additional_sympthoms_question_html + ':'">
        </div>
        <div class="mb-5">
            <div v-for="(sympthom, key) in store.sympthoms" :key="key">
                <div 
                    class="btn btn-white rio-pdq-button w-100 text-start px-4 mb-3 d-flex align-items-center" 
                    :class="{ 'active' : isSelected(sympthom) }" 
                    @click="select(sympthom)"
                >
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div v-html="langStore.lang.sympthoms[key + '_html']"></div>
                        <div>
                            <img :src="isSelected(sympthom) ? checked : placeholder">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" :disabled="nextDisabled" @click="store.nextStep">{{ langStore.lang.next }}</button>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';
import placeholder from '@images/rio-checked-placeholder.svg'
import checked from '@images/rio-checked.svg'

const store = usePdqStore();
const langStore = useLangStore();

const nextDisabled = computed(() => {
    return store.formData.sympthoms?.length == 0
})

const select = (value) => {
    const arr = store.formData.sympthoms ?? []
    
    const index = arr.indexOf(value)

    if(index > -1){
        arr.splice(index, 1)
    }else arr.push(value);
    
    store.formData.sympthoms = arr
}

const isSelected = (option) => {
    return store.formData.sympthoms?.includes(option)
}
</script>