import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-input"
export default class extends Controller {

  static targets = ['input', 'image']

  static values = {
    searchImage: {
      type: String,
    },
    clearImage: {
      type: String,
    },
  }

  connect() {
    this.setIcon();
  }

  clearSubmit(){
    if(this.inputTarget.value != ''){
      this.clearInput();
    }
  }

  setIcon(){
    if(this.inputTarget.value != ''){
      this.imageTarget.src = this.clearImageValue
    }else{
      this.imageTarget.src = this.searchImageValue
    }
  }

  clearInput(){
    this.inputTarget.value = ''
  }
}
