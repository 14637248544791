import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';

// Connects to data-controller="popper"
export default class extends Controller {

  static targets = ['button', 'dropdown']

  connect() {
    this.popperInstance =  createPopper(this.buttonTarget, this.dropdownTarget);
    window.addEventListener('click', e => {
      if(!this.element.contains(e.target)) this.hide();
    })
  }

  show(){
    this.dropdownTarget.setAttribute('data-show', '');

    this.popperInstance.update();
  }

  hide(){
    this.dropdownTarget.removeAttribute('data-show');
  }
}
