import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="yes-no-buttons"
export default class extends Controller {

  static targets = ['input', 'yes', 'no']

  connect() {
    if(this.inputTarget.value){
      this.setValue(this.inputTarget.value)
    }
  }

  handleButtonClick(e){
    this.setValue(e.target.getAttribute('data-value'))
  }

  setValue(value){
    value = Number(value)
    this.inputTarget.value = value
    
    if(value == 1){
      this.yesTarget.classList.add('active')
      this.noTarget.classList.remove('active')
    }else{
      this.noTarget.classList.add('active')
      this.yesTarget.classList.remove('active')
    }
  }
}
