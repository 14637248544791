import { Controller } from "@hotwired/stimulus"
import { initIntlTelInput } from "../utils/init-intl-tel-input"
// Connects to data-controller="reset-password"
export default class extends Controller {

  static targets = ['password', 'passwordConfirmation', 'strengthBar', 'strengthText', 'minimumchar', 'uppercase', 'lowercase', 'specialchar', 'errorContainer', 'submitButton']
  static values = {
    minimumchar: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    lowercase: {
      type: Boolean,
      default: false
    },
    specialchar: {
      type: Boolean,
      default: false
    },
    matchPassword: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    initIntlTelInput()
  }


  update_password(e) {
    let value = e.target.value
    this.errorContainerTarget.classList.remove('d-none')

    this.minimumcharValue = value.length >= 12
    this.toggleTextColor(this.minimumcharValue, this.minimumcharTarget)
    this.toggleicon(this.minimumcharValue, this.minimumcharTarget)

    this.uppercaseValue = !!value.match(/[A-Z]/)
    this.toggleTextColor(this.uppercaseValue, this.uppercaseTarget)
    this.toggleicon(this.uppercaseValue, this.uppercaseTarget)

    this.lowercaseValue = !!value.match(/[a-z]/)
    this.toggleTextColor(this.lowercaseValue, this.lowercaseTarget)
    this.toggleicon(this.lowercaseValue, this.lowercaseTarget)

    this.specialcharValue = !!value.match(/[^a-zA-Z0-9]/)
    this.toggleTextColor(this.specialcharValue, this.specialcharTarget)
    this.toggleicon(this.specialcharValue, this.specialcharTarget)

    this.calculatePasswordStrength()
    this.update_validations_state()
  }
  update_password_confirmation(e) {
    let value = e.target.value
    let password = this.passwordTarget.value
    this.matchPasswordValue = value == password
    if (this.matchPasswordValue) {
      this.passwordConfirmationTarget.classList.remove('is-invalid')
    } else {
      this.passwordConfirmationTarget.classList.add('is-invalid')
    }
    this.update_validations_state()
  }
  calculatePasswordStrength() {
    let strength = 0
    if (this.minimumcharValue) {
      strength += 25
    }
    if (this.uppercaseValue) {
      strength += 25
    }
    if (this.lowercaseValue) {
      strength += 25
    }
    if (this.specialcharValue) {
      strength += 25
    }
    this.strengthBarTarget.setAttribute('aria-valuenow', strength)
    this.strengthBarTarget.style.width = `${strength}%`
    if (strength >= 75) {
      this.strengthTextTarget.innerHTML = 'Strong'
      this.strengthBarTarget.classList.remove('bg-warning')
      this.strengthBarTarget.classList.add('bg-success')
      this.toggleTextColor(true, this.strengthTextTarget)

    } else {
      this.strengthTextTarget.innerHTML = 'Weak'
      this.strengthBarTarget.classList.remove('bg-success')
      this.strengthBarTarget.classList.add('bg-warning')
      this.toggleTextColor(false, this.strengthTextTarget)
    }
  }
  toggleicon(success, target) {
    let icon = target.querySelector('i')
    if (success) {
      icon.classList.remove('bi-x-circle-fill')
      icon.classList.add('bi-check-circle-fill')
    } else {
      icon.classList.remove('bi-check-circle-fill')
      icon.classList.add('bi-x-circle-fill')
    }
  }
  toggleTextColor(success, target) {
    if (success) {
      target.classList.remove('text-warning')
      target.classList.add('text-success')

    } else {
      target.classList.remove('text-success')
      target.classList.add('text-warning')

    }
  }

  update_validations_state() {
    let is_valid = this.minimumcharValue && this.uppercaseValue && this.lowercaseValue && this.specialcharValue && this.matchPasswordValue
    if (is_valid) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }


}
