import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="patient-modal"
export default class extends Controller {

  static targets = ["modal"]

  static values = { modal: Object }

  connect() {
  }

  get modal() {
    if (this._modal == undefined) {
      this._modal = new Modal(this.modalTarget);
      this.modalTarget.addEventListener('hidden.bs.modal', (e) => {
        if(e.target.id == 'add-new-patient'){
          Turbo.visit(window.location.href, { action: 'replace' });
        }
      })
    }
    return this._modal
  }

  showModal(e){
    this.modal.show()
  }

  hideModal(){
    this.modal.hide();
  }
}
